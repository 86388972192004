import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {PaymentSingleResultInterface} from '../interfaces/payment-single-result.interface'
import {PaymentResultInterface} from '../interfaces/payment-result.interface'

export interface GetAllPaymentParam {
  page?: number
  size?: number
  pipeline_id?: string
  stage_id?: string
  query?: string
}

export const GetAllPayment = async (
  params: GetAllPaymentParam
): Promise<PaymentResultInterface> => {
  try {
    let response = await ApiProgram.get('/payment', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: PaymentResultInterface = a.response?.data
    return response
  }
}

export const ShowPayment = async (id: string): Promise<PaymentSingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/payment/' + id)
    return response.data as PaymentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PaymentSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitPayment = async (form: any): Promise<PaymentSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/payment', form)
    return response.data as PaymentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PaymentSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdatePayment = async (
  id: string,
  form: any
): Promise<PaymentSingleResultInterface> => {
  try {
    let response = await ApiProgram.put('/payment/' + id, form)
    return response.data as PaymentSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: PaymentSingleResultInterface = a.response?.data
    return response
  }
}

export const DeletePayment = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/payment/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
