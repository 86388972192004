import {AxiosError} from 'axios'
import {Api, ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {BranchSingleResultInterface} from '../interfaces/branch-single-result.interface'
import {BranchResultInterface, BranchData} from '../interfaces/branch-result.interface'
import {BranchWithTotalDonationResultInterface} from '../interfaces/branch-with-total-donaiton-result.interface'

export interface GetAllBranchParam {
  page?: number
  size?: number
  name?: string
  start_date?: String
  end_date?: String
}

export interface BranchDashboardParam {
  start_date?: string
  end_date?: string
}

export const GetAllBranch = async (params: GetAllBranchParam): Promise<BranchResultInterface> => {
  try {
    let response = await Api.get('/branches', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BranchResultInterface = a.response?.data
    return response
  }
}

export const ShowBranch = async (id: string): Promise<BranchSingleResultInterface> => {
  try {
    let response = await Api.get('/branches/' + id)
    return response.data as BranchSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: BranchSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowBranchByBranchId = async (id: string): Promise<BranchSingleResultInterface> => {
  try {
    let response = await Api.get('/sub-branches-by-branch/' + id)
    return response.data as BranchSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: BranchSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitBranch = async (form: BranchData): Promise<BranchSingleResultInterface> => {
  try {
    let response = await Api.post('/branches', form)
    return response.data as BranchSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: BranchSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateBranch = async (
  id: string,
  form: BranchData
): Promise<BranchSingleResultInterface> => {
  try {
    let response = await Api.put('/branches/' + id, form)
    return response.data as BranchSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: BranchSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteBranch = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/branches/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetAllBranchWithTotalDonation = async (
  params: GetAllBranchParam
): Promise<BranchWithTotalDonationResultInterface> => {
  try {
    let response = await ApiProgram.get(
      '/branch-with-total-donation?start_date=2023-01-01&end_date=2023-12-31',
      {params: params}
    )
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BranchWithTotalDonationResultInterface = a.response?.data
    return response
  }
}
