import {createContext, useEffect, useState} from 'react'
import {ShowUser} from '../../../auth/user-management/providers/user.provider'
import {UserData} from '../../../auth/user-management/interfaces/user-result.interface'

export type GroupLeaderContextState = {
  user?: UserData
}

export const GroupLeaderContext = createContext<GroupLeaderContextState | null>(null)

interface GroupLeaderContextProviderProps {
  userId: string
  children: React.ReactNode
}

export const GroupLeaderContextProvider = ({userId, children}: GroupLeaderContextProviderProps) => {
  const [user, setUser] = useState<UserData>()

  const getUser = async (userId: string) => {
    if (userId !== '') {
      const response = await ShowUser(userId)
      if (response.status) {
        setUser(response.data)
      }
    }
  }

  useEffect(() => {
    getUser(userId)
  }, [userId])

  return (
    <GroupLeaderContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </GroupLeaderContext.Provider>
  )
}
