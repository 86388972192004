import {create} from 'zustand'
import {io, Socket} from 'socket.io-client'

export interface SocketState {
  initSocket: () => void
  socket?: Socket
  whatsappNumber?: string
  setWhatsappNumber: (value: string) => void
  getWhatsappNumber: () => void
}

export const useSocketStore = create<SocketState>((set, get) => ({
  initSocket: () => {
    const socket = io(process.env.REACT_APP_API_WHATSAPP_GATEWAY_SOCKET)
    set({socket: socket})
  },
  whatsappNumber: undefined,
  setWhatsappNumber: (value: string) => {
    localStorage.setItem('whatsappNumber', value)
    set({whatsappNumber: value})
  },
  getWhatsappNumber: () => {
    const wn = localStorage.getItem('whatsappNumber')
    set({whatsappNumber: wn})
  },
  socket: undefined,
}))
