import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {
  SubdistrictData,
  SubdistrictResultInterface,
} from '../interfaces/subdistrict-result.interface'
import {GetAllSubdistrict, GetAllSubdistrictParam} from '../providers/subdistrict.provider'
import {create} from 'zustand'

interface SubdistrictState {
  subdistrictLoading: boolean
  subdistrictItems: SubdistrictData[]
  subdistrictSelectData: SelectData[]
  subdistrictModel?: SubdistrictResultInterface
  getSubdistricts: (params: GetAllSubdistrictParam) => void
  onDelete?: (id: string, params: GetAllSubdistrictParam) => void
  reset?: () => void
}

export const useSubdistrictStore = create<SubdistrictState>((set, get) => ({
  reset: () => {
    set({
      subdistrictSelectData: [],
      subdistrictModel: undefined,
      subdistrictItems: [],
    })
  },
  subdistrictLoading: false,
  subdistrictItems: [],
  subdistrictSelectData: [],
  subdistrictModel: undefined,
  getSubdistricts: async (params: GetAllSubdistrictParam) => {
    set({subdistrictLoading: true})
    const response = await GetAllSubdistrict(params)
    if (response.status) {
      if (response.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          subdistrictSelectData: transformDatas,
          subdistrictItems: response.data.items,
        })
      } else {
        set({
          subdistrictSelectData: [],
          subdistrictItems: [],
        })
      }
    } else {
      set({
        subdistrictSelectData: [],
        subdistrictItems: [],
      })
    }
    set({subdistrictLoading: false})
  },
  onDelete: (id: string, params: GetAllSubdistrictParam) => {},
}))
