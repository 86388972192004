import {AxiosError} from 'axios'
import {Api, ApiCrm} from '../../../../../../api/api'
import {
  CustomerData,
  CustomerResultInterface,
} from '../../../../crm/customer-management/interfaces/customer-result.interface'
import {CustomerSingleResultInterface} from '../../../../crm/customer-management/interfaces/customer-single-result.interface'

export interface GetAllStudentParam {
  page?: number
  size?: number
  school_id?: string
}

export interface StudentExcelData {
  file: File
}

export interface StudentParam {
  school_id: string
}

export const GetAllStudent = async (
  params: GetAllStudentParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/students', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const SubmitStudent = async (form: CustomerData): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/students', form)
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitExcelStudent = async (
  form: FormData,
  param: StudentParam
): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/import-customer', form, {params: param})
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateStudent = async (
  id: string,
  form: CustomerData
): Promise<CustomerSingleResultInterface> => {
  try {
    let response = await Api.put('/students/' + id, form)
    return response.data as CustomerSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerSingleResultInterface = a.response?.data
    return response
  }
}
