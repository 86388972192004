import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {
  ProspectChannelResultInterface,
  ProspectChannelData,
} from '../interfaces/prospect-channel-result.interface'
import {ProspectChannelSingleResultInterface} from '../interfaces/prospect-channel-single-result.interface'

export interface GetAllProspectChannelParam {
  page?: number
  size?: number
  name?: string
  query?: string
}

export const GetAllProspectChannel = async (
  params: GetAllProspectChannelParam
): Promise<ProspectChannelResultInterface> => {
  try {
    let response = await ApiCrm.get('/prospect-channels', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ProspectChannelResultInterface = a.response?.data
    return response
  }
}

export const ProspectChannelBulkUpdate = async (items: any) => {
  try {
    let response = await ApiCrm.post('/prospect-channels-bulk', items)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    // let response: ProspectChannelSingleResultInterface = a.response?.data;
    // return response;
    return a.response?.data
  }
}

export const ShowProspectChannel = async (
  id: string
): Promise<ProspectChannelSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/prospect-channels/' + id)
    return response.data as ProspectChannelSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProspectChannelSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitProspectChannel = async (
  form: ProspectChannelData
): Promise<ProspectChannelSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/prospect-channels', form)
    return response.data as ProspectChannelSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProspectChannelSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateProspectChannel = async (
  id: string,
  form: ProspectChannelData
): Promise<ProspectChannelSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/prospect-channels/' + id, form)
    return response.data as ProspectChannelSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProspectChannelSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteProspectChannel = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/prospect-channels/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
