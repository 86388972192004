import {toast} from 'react-toastify'
import {create} from 'zustand'
import {
  generateFormLogic,
  generateInitValue,
  generateValueLogic,
} from '../../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../../interfaces/form-action-interfaces'
import {
  GetAllStudent,
  GetAllStudentParam,
  StudentParam,
  SubmitExcelStudent,
  SubmitStudent,
  UpdateStudent,
} from '../providers/student-management.provider'
import {TableHeaderInterface} from '../../../../../../interfaces/table-header.interface'
import {
  CustomerData,
  CustomerResultInterface,
} from '../../../../crm/customer-management/interfaces/customer-result.interface'
import {
  DeleteCustomer,
  GetAllCustomerParam,
} from '../../../../crm/customer-management/providers/customer.provider'
import {ShowFieldByReference} from '../../../../crm/properties-management/providers/field.provider'
import {
  Field,
  FieldData,
} from '../../../../crm/properties-management/interfaces/field-result.interface'
import {CustomerSingleResultInterface} from '../../../../crm/customer-management/interfaces/customer-single-result.interface'

interface StudentState {
  studentLoading: boolean
  studentItems: CustomerData[]
  studentSelectDatas: SelectData[]
  studentModel?: CustomerResultInterface
  getStudents: (params: GetAllStudentParam) => void
  onDelete: (id: string, params: GetAllStudentParam) => void

  studentHeaders: TableHeaderInterface[]
  studentColumns: any[]
}

export const useStudentStore = create<StudentState>((set, get) => ({
  studentLoading: false,
  studentItems: [],
  studentSelectDatas: [],
  studentModel: undefined,
  studentHeaders: [],
  studentColumns: [],
  getStudents: async (params: GetAllCustomerParam) => {
    set({studentLoading: true, studentItems: [], studentSelectDatas: []})
    const response = await GetAllStudent(params)
    set({studentModel: response})
    let columns = []
    let singleColumn = {}

    if (response) {
      if (response?.status) {
        if (response?.data?.items?.length > 0) {
          set({studentItems: response?.data?.items})

          // set select data
          let transformDatas: SelectData[] = []

          // set columns
          for (const item of response?.data?.items) {
            transformDatas.push({
              value: item?.id,
              label: `${item?.customer_values['nama-lengkap']} - ${item?.customer_values['email-perusahaan']}`,
            })

            singleColumn['fieldId'] = item?.id
            for (const key in item.customer_values) {
              if (item?.customer_values.hasOwnProperty(key)) {
                console.log(`${key}: ${item?.customer_values[key]}`)
                singleColumn[key] = item?.customer_values[key]
              }
            }
            columns.push(singleColumn)
          }
          set({
            studentSelectDatas: transformDatas,
            studentItems: response?.data?.items,
          })
        } else {
          set({studentItems: [], studentSelectDatas: []})
        }
      } else {
        set({studentItems: [], studentSelectDatas: []})
      }
    } else {
      set({studentItems: [], studentSelectDatas: []})
    }
    set({studentLoading: false})
  },
  onDelete: async (id: string, params: GetAllCustomerParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({studentLoading: true})
      const response = await DeleteCustomer(id)
      if (response.status) {
        toast.success(response.message)
        get().getStudents(params)
      } else {
        toast.error(response.message)
      }
    }
  },
}))

export interface CsvStudent {
  student_name: string
  phone_number: string
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
  csvModal: false,
}

export interface StudentFormState {
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam?: FormProps
  reset: () => void
  field?: CustomerData
  setField?: (field: CustomerData) => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
  onSubmitExcel: (form: FormData, StudentParam) => void
  open: boolean
  setOpen: (open: boolean) => void
  schoolId: string
  setSchoolId: (schoolId: string) => void
  fieldItems: FieldData[]
}

export const useStudentFormStore = create<StudentFormState>((set, get) => ({
  forms: [],
  fieldItems: [],
  field: undefined,
  setField: (field: CustomerData) => {
    set({field: field})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    let forms: FormInput[] = []
    set({forms: []})
    const response = await ShowFieldByReference('taqur_yakesma')
    if (response) {
      if (response.data?.length > 0) {
        set({fieldItems: response.data})
        const fields = get().field
        for (const item of response.data) {
          if (fields === null) {
            forms.push({
              id: item.id,
              title: item.fields.title,
              placeholder: item.fields.placeholder,
              type: item.fields.type,
              name: item.fields.name,
              value: undefined,
              selectData: item.fields.select_data,
              disabled: formParam.action === FormAction.VIEW ? true : false,
              required: item.fields.required,
            })
          } else {
            const theField = fields.customer_values[item.fields.name]

            if (theField) {
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                value: generateValueLogic(item.fields.type, theField),
                selectData: item.fields.select_data,
                disabled:
                  formParam.action === FormAction.VIEW
                    ? true
                    : item.fields?.name === 'nid'
                    ? true
                    : false,
                required: item.fields.required,
              })
            } else {
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                value: generateInitValue(item.fields.type, item.fields.value),
                selectData: item.fields.select_data,
                disabled: formParam.action === FormAction.VIEW ? true : false,
                required: item.fields.required,
              })
            }
          }
        }
      }
      set({forms: forms, formParam: formParam})
      return
    }
  },
  reset: () => {
    set(formInitialState)
    return
  },
  schoolId: '',
  setSchoolId: (schoolId: string) => {
    set({schoolId: schoolId})
  },
  onSubmit: async (schoolId: string) => {
    set({formLoading: true, submitDone: false})

    if (get().fieldItems?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }
    if (get().forms?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }

    // check required field
    let requiredError = false

    for (const item of get().forms) {
      if (item.required === true && (item.value === '' || item.value === undefined)) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    let values: Field[] = []
    const fields = get().fieldItems
    for (const item of fields) {
      const fieldIndex = get().forms.findIndex((el) => el.id === item.id)
      if (fieldIndex > -1) {
        values.push({
          id: item.id,
          title: item.fields.title,
          name: item.fields.name,
          placeholder: item.fields.placeholder,
          type: item.fields.type,
          select_data: item.fields.select_data,
          required: item.fields.required,
          is_header: item.fields.is_header,
          value: generateFormLogic(item.fields.type, get().forms[fieldIndex].value),
        })
      }
    }

    const form = {
      values: values,
      school_id: schoolId,
    }

    let res: CustomerSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitStudent(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateStudent(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  open: false,
  setOpen: (open: boolean) => {
    set({open: open})
  },
  onSubmitExcel: async (form: FormData, param: StudentParam) => {
    let res = await SubmitExcelStudent(form, param)
    if (res?.status) {
      toast.success(res?.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res?.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))
