import ApexCharts from 'apexcharts'
import {useEffect, useState} from 'react'
import {LoadingComponent} from '../../crm/properties-management/components/loading.component'
import {useDashboardStore} from '../states/dashboard.state'
import Chart from 'react-apexcharts'

const CustomerProfileChart = () => {
  const {getCustomerProfile, customerProfileLoading, customerProfiles} = useDashboardStore()
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({})
  const [series, setSeries] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])

  useEffect(() => {
    console.log(labels)
    getCustomerProfile()
  }, [])

  useEffect(() => {
    if (customerProfiles?.length > 0) {
      setSeries(customerProfiles?.map((item) => item.total))
      setLabels(customerProfiles?.map((item) => item.profile))
      setOptions({
        labels: customerProfiles?.map((item) => item.profile),
      })
    }
  }, [customerProfiles])

  return (
    <div className='col-xxl-6'>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Donatur Profile</span>
            <span className='text-muted fw-semibold fs-7'>Jumlah Donatur pada setiap profile</span>
          </h3>

          {/* begin::Toolbar */}
          {/* <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'year' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_year_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('year');
              }}>
              Year
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'month' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_month_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('month');
              }}>
              Month
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'week' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('week');
              }}>
              Week
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'day' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('day');
              }}>
              Day
            </a>
          </div> */}
          {/* end::Toolbar */}
        </div>

        {/* begin::Body */}
        <div className='card-body'>
          {customerProfileLoading ? <LoadingComponent /> : <></>}
          {/* begin::Chart */}
          <Chart options={options} series={series} type='donut' width='380' />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default CustomerProfileChart
