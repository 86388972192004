interface DashboardCardComponentProps {
  title?: string
  subtitle?: string
  color?: string
  value?: any
  icon?: React.ReactNode
  loading?: boolean
  className?: string
}

const DashboardCardComponent: React.FC<DashboardCardComponentProps> = ({
  title,
  color,
  value,
  icon,
  subtitle,
  loading = false,
  className = 'col-sm-12 col-xl-4',
}) => {
  return (
    <div className={className}>
      {/*begin::Card widget 2*/}
      <div className='card h-lg-100'>
        {/*begin::Body*/}
        <div className='card-body d-flex justify-content-between align-items-start flex-column'>
          {/*begin::Icon*/}
          {/* <div className="m-0"> */}
          {/*begin::Svg Icon | path: icons/duotune/maps/map004.svg*/}
          {/* <KTSVG 
              path={toAbsoluteUrl('/media/icons/duotune/maps/map004.svg')}
            /> */}
          {/*end::Svg Icon*/}
          {/* </div> */}
          {/*end::Icon*/}
          {/*begin::Section*/}
          <div className='d-flex flex-column'>
            {/*begin::Number*/}
            {/*begin::Follower*/}
            <div className='m-0'>
              <span className='fw-bold fs-2 text-gray-800'>{title}</span>
            </div>
            {/*end::Follower*/}

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {icon}
              {loading ? (
                <span className={`spinner-border spinner-border-lg text-${color}`}></span>
              ) : (
                <span className={`fw-bold fs-3x lh-1 ls-n2 text-${color}`}>{value}</span>
              )}
            </div>

            {/*end::Number*/}
          </div>
          {/*end::Section*/}
          {/*begin::Badge*/}
          <div className='mt-3'>
            <span className='fw-semibold fs-6 text-gray-400'>{subtitle}</span>
          </div>
          {/* <span className="badge badge-light-success fs-base"> */}
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
          {/* <KTSVG 
              path={toAbsoluteUrl('/media/icons/duotune/arrows/arr066.svg')}
            /> */}
          {/*end::Svg Icon*/}
          {/* 2.1% */}
          {/* </span> */}
          {/*end::Badge*/}
        </div>
        {/*end::Body*/}
        <span
          className={`bullet-custom position-absolute bottom-0 w-100 h-4px bg-${color}`}
          style={{
            borderEndEndRadius: '0.625rem',
            borderBottomLeftRadius: '0.625rem',
          }}
        ></span>
      </div>
      {/*end::Card widget 2*/}
    </div>
  )
}

export default DashboardCardComponent
