import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {ClientResultInterface} from '../interfaces/clients-result.interface'

export interface GetClientParam {
  page: number
  size: number
  start_date: string
  end_date: string
}

export const GetAllClient = async (params: GetClientParam): Promise<ClientResultInterface> => {
  try {
    let response = await ApiProgram.get('/clients', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ClientResultInterface = a.response?.data
    return response
  }
}
