import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {UserData, UserResultInterface} from '../interfaces/user-result.interface'
import {UserSingleResultInterface} from '../interfaces/user-single-result.interface'
import {
  DeleteUser,
  GetAllUser,
  GetAllUserParam,
  GetMitra,
  ShowUser,
  SubmitUser,
  UpdateUser,
  UpdateUserMe,
} from '../providers/user.provider'
import {sanitizeCompanyId} from '../../../../../../_metronic/helpers'

interface UserState {
  userLoading: boolean
  userItems: UserData[]
  userSelectDatas: SelectData[]
  userModel?: UserResultInterface
  getUsers: (params: GetAllUserParam) => void

  // Get All Mitra
  mitraLoading: boolean
  mitraItems: UserData[]
  mitraModel: UserResultInterface
  getMitra: (params: GetAllUserParam) => void

  onDelete: (id: string, params: GetAllUserParam) => void
  userSingleModel?: UserSingleResultInterface
  getShowUser: (userId: string) => void
}

export const useUserStore = create<UserState>((set, get) => ({
  userLoading: false,
  userItems: [],
  userSelectDatas: [],
  userModel: undefined,
  getUsers: async (params: GetAllUserParam) => {
    set({userLoading: true, userItems: [], userSelectDatas: []})
    const response = await GetAllUser(params)
    set({userModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `${item?.full_name ?? ''} - ${item?.role_name}`,
            value: item.id,
          })
        }
        set({
          userItems: response.data.items,
          userSelectDatas: transformDatas,
        })
      } else {
        set({
          userItems: [],
          userSelectDatas: [],
        })
      }
    } else {
      set({
        userItems: [],
        userSelectDatas: [],
      })
    }
    set({userLoading: false})
  },
  mitraLoading: false,
  mitraItems: [],
  mitraModel: undefined,
  getMitra: async (params: GetAllUserParam) => {
    set({mitraLoading: true, mitraItems: []})
    const response = await GetMitra(params)
    set({mitraModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `${item?.full_name ?? ''} - ${item?.role_name}`,
            value: item.id,
          })
        }
        set({
          mitraItems: response.data.items,
        })
      } else {
        set({
          mitraItems: [],
        })
      }
    } else {
      set({
        mitraItems: [],
      })
    }
    set({mitraLoading: false})
  },
  onDelete: async (id: string, params: GetAllUserParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({userLoading: true})
      const response = await DeleteUser(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getUsers(params)
      set({userLoading: false})
    }
  },
  getShowUser: async (userId: string) => {
    if (userId !== '') {
      const response = await ShowUser(userId)
      if (response.status) {
        set({userSingleModel: response})
      } else {
        set({userSingleModel: undefined})
      }
    } else {
      set({userSingleModel: undefined})
    }
  },
}))

interface UserFormState {
  field?: UserData
  setField?: (item: UserData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  generateFormsMe: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  onSubmit: () => void
  onSubmitMe: () => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useUserFormStore = create<UserFormState>((set, get) => ({
  field: undefined,
  setField: (item: UserData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateFormsMe: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'avatar',
        title: 'Avatar',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'avatar',
        value: (formParam.data as UserData).avatar,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        id: 'email',
        title: 'Email',
        placeholder: 'Email...',
        type: 'email',
        name: 'email',
        value: sanitizeCompanyId((formParam.data as UserData).email) ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'phone_number',
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phone_number',
        value: sanitizeCompanyId((formParam.data as UserData).phone_number) ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'password',
        title: 'Password',
        placeholder: 'Password...',
        type: 'password',
        name: 'password',
        value: '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'full_name',
        title: 'Full Name',
        placeholder: 'Full Name...',
        type: 'text',
        name: 'full_name',
        value: (formParam.data as UserData)?.full_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      // {
      //   id: 'affiliate_code',
      //   title: 'Kode Affiliasi',
      //   placeholder: 'Kode Affiliasi',
      //   type: 'text',
      //   name: 'affiliate_code',
      //   value: (formParam.data as UserData)?.affiliate_code ?? '',
      //   disabled: true,
      //   required: true,
      // },
    ]

    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'avatar',
        title: 'Avatar',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'avatar',
        value: get().field?.avatar,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        id: 'email',
        title: 'Email',
        placeholder: 'Email...',
        type: 'email',
        name: 'email',
        value: sanitizeCompanyId(get().field?.email) ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'phone_number',
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phone_number',
        value: sanitizeCompanyId(get().field?.phone_number) ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'password',
        title: 'Password',
        placeholder: 'Password...',
        type: 'password',
        name: 'password',
        value: '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'full_name',
        title: 'Full Name',
        placeholder: 'Full Name...',
        type: 'text',
        name: 'full_name',
        value: get().field?.full_name,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'role_id',
        title: 'Role',
        placeholder: 'Role...',
        type: 'select-with-text',
        name: 'role_id',
        value: {
          label: get().field?.role_name,
          value: get().field?.role_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'percentage_fee',
        title: 'Percentage Fee Affiliate Program',
        placeholder: 'Percentage Fee',
        type: 'number',
        name: 'percentage_fee',
        value: get().field?.percentage_fee ?? 0,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    if (
      formParam?.companyId === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ||
      formParam?.companyId === 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4'
    ) {
      forms.push({
        id: 'branch_id',
        title: 'Branch',
        placeholder: 'Branch...',
        type: 'select-with-text',
        name: 'branch_id',
        value: {
          label: get().field?.branch_name,
          value: get().field?.branch_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'affiliate_code',
        title: 'Kode Affiliasi',
        placeholder: 'Kode Affiliasi',
        type: 'text',
        name: 'affiliate_code',
        value: get().field?.affiliate_code,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'verified',
        title: 'Verified?',
        placeholder: 'Verified?',
        type: 'checkbox',
        name: 'verified',
        value: get().field?.verified ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
    } else {
      forms.push({
        id: 'province_id',
        title: 'Provinsi',
        placeholder: 'Provinsi...',
        type: 'select-with-text',
        name: 'province_id',
        value: {
          label: get().field?.province_name,
          value: get().field?.province_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'city_id',
        title: 'Kota/Kabupaten',
        placeholder: 'Kota/Kabupaten...',
        type: 'select-with-text',
        name: 'city_id',
        value: {
          label: get().field?.city_name,
          value: get().field?.city_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'district_id',
        title: 'Kecamatan',
        placeholder: 'Kecamatan...',
        type: 'select-with-text',
        name: 'district_id',
        value: {
          label: get().field?.district_name,
          value: get().field?.district_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'subdistrict_id',
        title: 'Kelurahan',
        placeholder: 'Kelurahan...',
        type: 'select-with-text',
        name: 'subdistrict_id',
        value: {
          label: get().field?.subdistrict_name,
          value: get().field?.subdistrict_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'affiliate_code',
        title: 'Kode Affiliasi',
        placeholder: 'Kode Affiliasi',
        type: 'text',
        name: 'affiliate_code',
        value: get().field?.affiliate_code,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
      forms.push({
        id: 'verified',
        title: 'Verified?',
        placeholder: 'Verified?',
        type: 'checkbox',
        name: 'verified',
        value: get().field?.verified ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      })
    }

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmitMe: async () => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: UserSingleResultInterface = {}
    let error: any

    if (get().formParam.action === FormAction.UPDATE) {
      res = await UpdateUserMe(get().formParam?.id, form)
    }

    error = res.error
    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      if (error === 'Gagal memproses data') {
        toast.error('Nomor Hp / Email sudah terdaftar')
      } else {
        toast.error('Data Belum Lengkap')
      }

      set({submitDone: false, formLoading: false})
      return
    }
  },
  onSubmit: async () => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: UserSingleResultInterface = {}
    let error: any

    if (get().formParam.action === FormAction.CREATE) res = await SubmitUser(form)

    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateUser(get().formParam?.id, form)
    error = res.error
    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      if (error === 'Gagal memproses data') {
        toast.error('Nomor Hp / Email sudah terdaftar')
      } else {
        toast.error('Data Belum Lengkap')
      }

      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
