export const generateCurrencyFormat = (value: number, locale?: string): string => {
  let formatter: Intl.NumberFormat = undefined

  // if (locale !== null && locale !== undefined && locale !== '') {
  //   if (locale === 'Malaysia Ringgit') {
  //     formatter = new Intl.NumberFormat('ms-MY', {
  //       style: 'currency',
  //       currency: 'MYR',
  //       minimumFractionDigits: 0,
  //     })
  //   } else {
  //     formatter = new Intl.NumberFormat('id-ID', {
  //       style: 'currency',
  //       currency: 'IDR',
  //       minimumFractionDigits: 0,
  //     })
  //   }
  // } else {
  //   if (process.env.REACT_APP_CURRENCY === 'IDR') {
  //     formatter = new Intl.NumberFormat('id-ID', {
  //       style: 'currency',
  //       currency: 'IDR',
  //       minimumFractionDigits: 0,
  //     })
  //   } else if (process.env.REACT_APP_CURRENCY === 'MYR') {
  //     formatter = new Intl.NumberFormat('ms-MY', {
  //       style: 'currency',
  //       currency: 'MYR',
  //       minimumFractionDigits: 0,
  //     })
  //   } else {
  //     formatter = new Intl.NumberFormat('id-ID', {
  //       style: 'currency',
  //       currency: 'IDR',
  //       minimumFractionDigits: 0,
  //     })
  //   }
  // }

  formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: localStorage.getItem('SELECTED_CURRENCY') ?? 'IDR',
    minimumFractionDigits: 0,
  })

  return formatter.format(value * +(localStorage.getItem('EXCHANGE_RATE') ?? 1))
}
