import {ComponentType} from 'react'
import {Handle, NodeProps, Position} from 'reactflow'
import {generateImageUrl} from '../../../../../../_metronic/helpers/generate-image-url'
import {useFlowBuilderStore, useReactFlowStore} from '../states/flow-builder.state'

export type CardNodeData = {
  title?: string
  message?: string
  buttons?: string[]
  lists?: string[]
  text_based_actions?: string[]
  text_based_action_numbers?: string[]
  type?: string
  media?: string
  save_as?: string
  product_id?: string
  product_name?: string
}

const CardNodeComponentV2: ComponentType<NodeProps<CardNodeData>> = ({id, data}) => {
  const {setSelectedNode} = useFlowBuilderStore()
  const {nodes} = useReactFlowStore()

  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        id={'top'}
        style={{
          width: 15,
          height: 15,
        }}
      />
      <div
        className='card shadow-sm'
        style={{
          maxWidth: 250,
        }}
      >
        <div className='card-header'>
          <h3 className='card-title'>{data?.title}</h3>
          {/* <div className="card-toolbar">
                  <button 
                    type="button" 
                    className="btn btn-sm btn-light"
                    onClick={e => {
                      alert(id)
                    }}>
                      Action
                  </button>
              </div> */}
        </div>
        <div className='card-body'>
          <div className='d-flex flex-column'>
            <span className='fw-semibold fs-6 text-gray-600'>{'Message'}</span>
            <span className='fw-bold fs-6' style={{maxLines: 2}}>
              {data?.message?.substring(0, 50)}
              {data?.message?.length > 50 ? '...' : ''}
            </span>
            <span className='fw-semibold fs-6 text-gray-600'>{'Type'}</span>
            <span className='fw-bold fs-6'>{data?.type}</span>
            {data?.buttons?.length > 0 ? (
              <>
                <span className='fw-semibold fs-6 text-gray-600'>{'Buttons'}</span>
                <ul>
                  {data?.buttons?.map((item: string, index: number) => (
                    <li key={index}>
                      <span className='fw-bold fs-6'>{item}</span>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
            {data?.lists?.length > 0 ? (
              <>
                <span className='fw-semibold fs-6 text-gray-600'>{'Buttons'}</span>
                <ul>
                  {data?.lists?.map((item: string, index: number) => (
                    <li key={index}>
                      <span className='fw-bold fs-6'>{item}</span>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
            {data?.text_based_actions?.length > 0 ? (
              <>
                <span className='fw-semibold fs-6 text-gray-600'>{'Text Based Actions'}</span>
                <ul>
                  {data?.text_based_actions?.map((item: string, index: number) => (
                    <li key={index} className={'mt-2'}>
                      <span className='fw-bold fs-6'>
                        {item.substring(0, 20)}
                        {item?.length > 10 ? '...' : ''}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
            {data?.media !== '' ? (
              <>
                <span className='fw-semibold fs-6 text-gray-600'>{'Media'}</span>
                <img
                  src={generateImageUrl(data?.media)}
                  alt={data?.media}
                  style={{
                    borderRadius: 10,
                    width: 150,
                    height: 75,
                    objectFit: 'cover',
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <button
            type='button'
            className='btn btn-sm btn-primary mt-5'
            onClick={(e) => {
              setSelectedNode(nodes?.find((el) => el.id === id))
            }}
          >
            Flow Message
          </button>
          {/* <InputTextArea
              title="Message"
              placeholder="Enter Message..."
              onChangeTextArea={onChange}
              type={'text-area'}
              disabled={false} 
              id={"message"} 
              name={"message"}
              value={data.message ?? ""}
            /> */}
        </div>
        {/* <div className="card-footer">
              Footer
          </div> */}
      </div>
      {data?.buttons?.length > 0 &&
        data?.buttons?.map((item: string, index: number) => (
          <Handle
            key={index}
            type='source'
            position={Position.Right}
            id={item}
            style={{
              top: (index + 1) * 25 + 170,
              width: 15,
              height: 15,
            }}
          />
        ))}
      {data?.lists?.length > 0 &&
        data?.lists?.map((item: string, index: number) => (
          <Handle
            key={index}
            type='source'
            position={Position.Right}
            id={item}
            style={{
              top: (index + 1) * 25 + 170,
              width: 15,
              height: 15,
            }}
          />
        ))}
      {data?.text_based_action_numbers?.length > 0 &&
        data?.text_based_action_numbers?.map((item: string, index: number) => (
          <Handle
            key={index}
            type='source'
            position={Position.Right}
            id={item.toString()}
            style={{
              top: (index + 1) * 28 + 207,
              width: 15,
              height: 15,
            }}
          />
        ))}
      {data?.lists?.length === 0 &&
        data?.buttons?.length === 0 &&
        data?.text_based_action_numbers?.length === 0 && (
          <Handle
            type='source'
            position={Position.Right}
            id={'bottom'}
            style={{
              width: 15,
              height: 15,
            }}
          />
        )}
      {/* <Handle type="source" position={Position.Right} id={'bottom'} /> */}
      {/* <Handle
          type="source" 
          position={Position.Right} 
          id={'a'}
        />
      <Handle
          type="source" 
          position={Position.Right} 
          id={'b'}
          style={{
            top: 90
          }} 
        />
      <Handle
          type="source" 
          position={Position.Right} 
          id={'c'}
          style={{
            top: 100
          }} 
        /> */}
      {/* <Handle type="source" position={Position.Bottom} id="b" style={handleStyle} /> */}
    </>
  )
}

export default CardNodeComponentV2
