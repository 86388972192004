import {useEffect} from 'react'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import MiniCardComponent from './mini-card-dashboard.component'
import {useDashboardAffiliateStore} from '../../../program/program-affiliate/states/program-affiliate.state'
import {useParams} from 'react-router-dom'

const TotalDonationAffiliatorCardComponent = () => {
  const params = useParams()

  const {
    getTotalDonationAffiliateV2,
    totalDonationAffiliateV2Items,
    totalDonationAffiliateV2Loading,
  } = useDashboardAffiliateStore()

  useEffect(() => {
    if (params?.userId) {
      getTotalDonationAffiliateV2(params?.userId, false)
    }
  }, [params?.userId])

  return (
    <>
      <MiniCardComponent
        title='Total Donation'
        color='success'
        value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
        icon={<i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateV2Loading}
      />
      <MiniCardComponent
        title='Commission'
        color='warning'
        value={0}
        icon={<i className={`bi bi-cash-coin text-black fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateV2Loading}
      />
      <MiniCardComponent
        title='Total Donatur'
        color='secondary'
        value={7}
        icon={<i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateV2Loading}
      />
      <MiniCardComponent
        title='Target Donasi'
        color='primary'
        value={generateCurrencyFormat(10000000)}
        icon={<i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateV2Loading}
      />
    </>
  )
}

export default TotalDonationAffiliatorCardComponent
