import {create} from 'zustand'
import {PromptAIResponse} from '../interfaces/prompt-ai-response.interface'
import {GetGenAi} from '../providers/program.provider'

interface GenAiState {
  genAiLoading: boolean
  genAiResponse: PromptAIResponse
  getGenAi: (prompt: string) => void
  resetGenAi: () => void
}

export const useGenAiStore = create<GenAiState>((set, get) => ({
  genAiLoading: false,
  genAiResponse: null,
  getGenAi: async (prompt: string) => {
    set({genAiLoading: true})
    const response = await GetGenAi(prompt)
    set({genAiResponse: response, genAiLoading: false})
  },
  resetGenAi: () => {
    set({genAiLoading: false, genAiResponse: null})
  },
}))
