import {Chart} from 'react-google-charts'
import {useIntl} from 'react-intl'

export function BarChart(props: any) {
  const intl = useIntl()

  const options = {
    chart: {
      title: `${intl.formatMessage({id: 'APPS.TRANSACTION'})} Type`,
      subtitle: 'Total Donation: 2023-2025',
    },
  }
  return (
    <>
      <div className='col-sm-6 col-md-6'>
        <Chart chartType='Bar' width='100%' height='350px' data={props.data} options={options} />
      </div>
    </>
  )
}
