import create from 'zustand'
import {SchoolResultInterface, SchoolData} from '../interfaces/school-result.interface'
import {SchoolSingleResultInterface} from '../interfaces/school-single-result.interface'
import {
  GetAllSchoolParam,
  GetAllSchool,
  DeleteSchool,
  UpdateSchool,
  SubmitSchool,
} from '../providers/school.provider'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../../interfaces/form-action-interfaces'
import {toast} from 'react-toastify'
import {FieldData} from '../../../../crm/properties-management/interfaces/field-result.interface'
import {generateFormLogic} from '../../../../../../../_metronic/helpers/generate-form-logic'

interface SchoolState {
  schoolLoading: boolean
  schoolItems: SchoolData[]
  schoolModel?: SchoolResultInterface
  schoolSelectDatas: SelectData[]
  getAllSchool: (params: GetAllSchoolParam) => void
  onDelete: (id: string, params: GetAllSchoolParam) => void
}

export const useSchoolState = create<SchoolState>((set, get) => ({
  schoolLoading: false,
  schoolItems: [],
  schoolModel: undefined,
  schoolSelectDatas: [],
  getAllSchool: async (params: GetAllSchoolParam) => {
    set({
      schoolLoading: true,
      schoolItems: [],
      schoolSelectDatas: [],
    })
    const response = await GetAllSchool(params)
    set({schoolModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response?.data?.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          schoolItems: response?.data?.items,
          schoolSelectDatas: transformDatas,
        })
      } else {
        set({
          schoolItems: [],
          schoolSelectDatas: [],
        })
      }
    } else {
      set({
        schoolItems: [],
        schoolSelectDatas: [],
      })
    }
    set({
      schoolLoading: false,
    })
  },
  onDelete: async (id: string, params: GetAllSchoolParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({schoolLoading: true})
      const response = await DeleteSchool(id)
      if (response?.status) {
        toast.success(response?.message)
        get().getAllSchool(params)
      } else {
        toast.error(response?.message)
      }
    }
  },
}))

export interface SchoolFormState {
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam?: FormProps
  reset: () => void
  field?: SchoolData
  setField?: (field: SchoolData) => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
  fieldItems: FieldData[]
  setFormSelectData: (index: number, selectData: SelectData[]) => void
}

const formInitialState = {
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useSchoolFormStore = create<SchoolFormState>((set, get) => ({
  forms: [],
  fieldItems: [],
  field: undefined,
  setField: (field: SchoolData) => {
    set({field: field})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'school_name',
        title: 'School Name',
        placeholder: 'School...',
        type: 'text',
        name: 'name',
        value: get().field?.name,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'school_code',
        title: 'School Code',
        placeholder: 'School Code...',
        type: 'text',
        name: 'school_code',
        value: get().field?.school_code,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'level',
        title: 'Level',
        placeholder: 'Level',
        type: 'text',
        name: 'level',
        value: get().field?.level,
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: SchoolSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitSchool(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateSchool(get().formParam?.id, form)

    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
