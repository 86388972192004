// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import ClassicEditor from './classic-editor';
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {useEffect, useState} from 'react'
import {useGenAiStore} from '../../../apps/program/program/states/genai.state'

interface RichTextAiV2Props {
  title?: string
  value?: any
  onChange?: (event: any, editor: any) => void
  onBlur?: (event: any, editor: any) => void
  onFocus?: (event: any, editor: any) => void
  name?: string
  disabled?: boolean
  required?: boolean
  getEditor?: (editor: any) => void
}

const RichTextAiV2: React.FC<RichTextAiV2Props> = ({
  title = '',
  value,
  onChange,
  onBlur,
  onFocus,
  name,
  disabled = false,
  required = true,
  getEditor,
}) => {
  const {genAiLoading, genAiResponse, getGenAi} = useGenAiStore()
  const [val, setVal] = useState<string>(value)
  const [prompt, setPrompt] = useState<string>('')

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    if (genAiResponse !== null) {
      const theVal = val
      setVal(theVal.concat(' ', genAiResponse.data?.response ?? ''))
    }
  }, [genAiResponse])

  const uploadAdapter = (loader: any) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append('file', file)
            let headers = new Headers()
            headers.append('storage-api-key', process.env.REACT_APP_STORAGE_KEY)
            fetch(process.env.REACT_APP_STORAGE_URL + '/storage/single', {
              method: 'post',
              headers: headers,
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  // default: `${process.env.REACT_APP_ASSETS_URL}/${res.data.id}`,
                  default: `${process.env.REACT_APP_ASSETS_URL}/${res.data.id}?w=534&fit=crop&auto=format,compress`,
                })
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }

  return (
    <>
      <div className={`fv-row mb-7 col-12`}>
        <label className={`d-block fw-semibold fs-6 mb-5`}>
          <b>New Feature: Gen AI Program Description</b>
        </label>
        <input
          onChange={(e) => {
            setPrompt(e.target?.value)
          }}
          placeholder={'Isi dengan kalimat yang ajaib'}
          value={prompt}
          type={'text'}
          name={'gen_ai_prompt'}
          // disabled={disabled}
          // hidden={hidden}
          className={'form-control mb-3 mb-lg-0'}
          // required={required}
        />
        <button
          className='btn btn-primary mt-2'
          onClick={(e) => {
            e.preventDefault()
            if (!genAiLoading) {
              getGenAi(prompt)
            }
          }}
        >
          {genAiLoading ? 'Mohon tunggu, sedang mengenerate kata kata ajaib...' : 'Generate'}
        </button>
      </div>
      <div className='col-md-12 col-md-12 mb-5 prose'>
        <label className={`form-label`}>
          {title}
          {required === true && <span style={{marginLeft: 5, color: 'red'}}>*</span>}
        </label>
        <CKEditor
          editor={Editor}
          data={val}
          name={name}
          disabled={disabled}
          config={{
            extraPlugins: [uploadPlugin],
            mediaEmbed: {
              previewsInData: true,
            },
          }}
          onReady={(editor: any) => {
            // You can store the "editor" and use when it is needed.
            getEditor(editor)
          }}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    </>
  )
}

export default RichTextAiV2
