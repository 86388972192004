import {useEffect, useState} from 'react'
import {useTaqurTransactionFormStore} from '../states/transaction-management.state'
import {KTSVG} from '../../../../../../../_metronic/helpers'

const ImportTaqurTransactionModalComponent = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <TaqurTransactionImportModalHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <TaqurTransactionImportModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TaqurTransactionImportModalHeader = () => {
  const {setField, formParam} = useTaqurTransactionFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const TaqurTransactionImportModalFormWrapper = () => {
  const {onSubmitExcel, setOpen} = useTaqurTransactionFormStore()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files)
      setSelectedFile(e.target.files[0])
    }
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          if (selectedFile) {
            const formData = new FormData()
            formData.append('file', selectedFile)
            onSubmitExcel(formData)
          }
        }}
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        ></div>
        <input type='file' onChange={handleFileChange} />
        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => setOpen(false)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              if (selectedFile) {
                const formData = new FormData()
                formData.append('file', selectedFile)
                onSubmitExcel(formData)
                setOpen(false)
              }
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default ImportTaqurTransactionModalComponent
