import {AxiosError} from 'axios'
import {ChartOfAccountResultInterface} from '../interfaces/chart-of-account-result.interface'
import {ApiFinance} from '../../../../api/api'

export interface GetAllChartOfAccountParam {
  page?: number
  size?: number
  is_group?: boolean
}

export const GetAllChartOfAccount = async (
  params: GetAllChartOfAccountParam
): Promise<ChartOfAccountResultInterface> => {
  try {
    let response = await ApiFinance.get('/chart-of-accounts', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ChartOfAccountResultInterface = a.response?.data
    return response
  }
}
