import {AxiosError} from 'axios'
import {Api} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {CityData, CityResultInterface} from '../interfaces/city-result.interface'
import {CitySingleResultInterface} from '../interfaces/city-single-result.interface'

export interface GetAllCityParam {
  page?: number
  size?: number
  name?: string
  province_id?: string
}

export const GetAllCity = async (params: GetAllCityParam): Promise<CityResultInterface> => {
  try {
    let response = await Api.get('/cities', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CityResultInterface = a.response?.data
    return response
  }
}

export const ShowCity = async (id: string): Promise<CitySingleResultInterface> => {
  try {
    let response = await Api.get('/cities/' + id)
    return response.data as CitySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CitySingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitCity = async (form: CityData): Promise<CitySingleResultInterface> => {
  try {
    let response = await Api.post('/cities', form)
    return response.data as CitySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CitySingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateCity = async (
  id: string,
  form: CityData
): Promise<CitySingleResultInterface> => {
  try {
    let response = await Api.put('/cities/' + id, form)
    return response.data as CitySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: CitySingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteCity = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/cities/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
