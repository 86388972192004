import {AxiosError} from 'axios'
import {Api} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {
  SubdistrictData,
  SubdistrictResultInterface,
} from '../interfaces/subdistrict-result.interface'
import {SubdistrictSingleResultInterface} from '../interfaces/subdistrict-single-result.interface'

export interface GetAllSubdistrictParam {
  page?: number
  size?: number
  district_id?: string
  name?: string
}

export const GetAllSubdistrict = async (
  params: GetAllSubdistrictParam
): Promise<SubdistrictResultInterface> => {
  try {
    let response = await Api.get('/subdistricts', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: SubdistrictResultInterface = a.response?.data
    return response
  }
}

export const ShowSubdistrict = async (id: string): Promise<SubdistrictSingleResultInterface> => {
  try {
    let response = await Api.get('/subdistricts/' + id)
    return response.data as SubdistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SubdistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitSubdistrict = async (
  form: SubdistrictData
): Promise<SubdistrictSingleResultInterface> => {
  try {
    let response = await Api.post('/subdistricts', form)
    return response.data as SubdistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SubdistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateSubdistrict = async (
  id: string,
  form: SubdistrictData
): Promise<SubdistrictSingleResultInterface> => {
  try {
    let response = await Api.put('/subdistricts/' + id, form)
    return response.data as SubdistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SubdistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteSubdistrict = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/subdistricts/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingSubdistrict = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await Api.put('/subdistricts-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
