import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {
  ContactGroupData,
  ContactGroupResultInterface,
} from '../interfaces/contact-group-result.interface'
import {ContactGroupSingleResultInterface} from '../interfaces/contact-group-single-result.interface'

export interface GetAllContactGroupParam {
  page?: number
  size?: number
  search?: string
  provinceId?: string
}

export const GetAllContactGroup = async (
  params: GetAllContactGroupParam
): Promise<ContactGroupResultInterface> => {
  try {
    let response = await ApiCrm.get('/contact-groups', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ContactGroupResultInterface = a.response?.data
    return response
  }
}

export const ShowContactGroup = async (id: string): Promise<ContactGroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/contact-groups/' + id)
    return response.data as ContactGroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactGroupSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitContactGroup = async (
  form: ContactGroupData
): Promise<ContactGroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/contact-groups', form)
    return response.data as ContactGroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactGroupSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateContactGroup = async (
  id: string,
  form: ContactGroupData
): Promise<ContactGroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/contact-groups/' + id, form)
    return response.data as ContactGroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactGroupSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteContactGroup = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/contact-groups/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingContactGroup = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put('/contact-groups-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
