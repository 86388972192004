import {useEffect} from 'react'
import {useCustomerStore} from '../../../crm/customer-management/states/customer.state'

const CustomerByAffiliatorListComponent = () => {
  const {
    customerByAffiliatorItems,

    navList,
    activeNavIndex,
    setActiveNavIndex,
  } = useCustomerStore()

  // useEffect(() => {
  //   getCustomerByAffiliator(
  //     {
  //       page: 0,
  //       size: 10,
  //       reference: navList[activeNavIndex].reference,
  //     },
  //     params?.userId
  //   )
  // }, [activeNavIndex])

  useEffect(() => {
    console.log(customerByAffiliatorItems)
  }, [])

  // const handlePageClick = (e: any) => {
  //   const selectedPage = e.selected
  //   getCustomerByAffiliator(
  //     {
  //       page: selectedPage,
  //       size: 10,
  //       reference: navList[activeNavIndex].reference,
  //     },
  //     params?.userId
  //   )
  // }

  return (
    <>
      <div className='card' style={{marginBottom: 10}}>
        <div className='card-header border-0'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {navList?.map((item, index) => {
                return (
                  <li key={index} className='nav-item'>
                    <a
                      className={`nav-link text-active-primary me-6 ${
                        activeNavIndex === index ? 'active' : 'false'
                      }`}
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        setActiveNavIndex(index)
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      {/* <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Donatur {navList[activeNavIndex].name}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Donatur {navList[activeNavIndex].name}
            </span>
          </h3>
        </div> */}
      {/* <div className='card-body py-3'>
          <div className='table-responsive'>
            {customerByAffiliatorLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  {customerHeaders?.map((item, index) => {
                    return (
                      <th
                        key={item.headerId}
                        className={item.headerId === 'action' ? 'text-end' : ''}
                      >
                        {item.headerName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {customerByAffiliatorItems.length === 0 ? (
                  <tr>
                    <td colSpan={customerHeaders?.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  customerByAffiliatorItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        {item?.values?.map((v, k) => {
                          if (customerHeaders.findIndex((el) => el.headerId === v.name) !== -1) {
                            return <td key={k}>{v.value}</td>
                          }
                          return <></>
                        })}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={customerByAffiliatorModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div> */}
      {/* </KTCard> */}
      {/* {field !== undefined && <CustomerModalComponent />} */}
    </>
  )
}

const CustomerByAffiliatorListWrapper = () => {
  return (
    <>
      <CustomerByAffiliatorListComponent />
    </>
  )
}

export default CustomerByAffiliatorListWrapper
