import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdminBranch = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/admin-branch-dashboard/dashboard'
        title='Dashboard'
        hasBullet={true}
      />
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItem
          to='/apps/chat/broadcast-management/broadcasts'
          title='Broadcast'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/chat/contact-group-management/contact-groups'
          title='Contact Group'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='CRM'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/channel-management/channel'
          title='Channel Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Campaign Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainAdminBranch
