import {useEffect} from 'react'
import {useParams} from 'react-router'

import TotalDonationAffiliatorCardComponent from '../../../auth/affiliator-management/components/total-donation-affiliator-card.component'
import DonationHistoryAffiliatorComponent from '../../../program/program-affiliate/components/donation-history-affiliator.component'
import {useDashboardAffiliateStore} from '../../../program/program-affiliate/states/program-affiliate.state'

const AffiliatorDetailScreen = () => {
  const params = useParams()

  const {getTotalDonationAffiliateV2} = useDashboardAffiliateStore()

  useEffect(() => {
    getTotalDonationAffiliateV2(params.userId, false)
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5'>
        <TotalDonationAffiliatorCardComponent />
        <DonationHistoryAffiliatorComponent />
      </div>
    </>
  )
}

export default AffiliatorDetailScreen
