import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../../interfaces/form-action-interfaces'

import {
  DonationData,
  DonationResultInterface,
} from '../../../donation/interfaces/donation-result.interface'
import {
  SubmitDonation,
  UpdateDonation,
  GetAllDonationParam,
  DeleteDonation,
  GetAllTransactionTaqur,
} from '../../../donation/providers/donation.provider'
import {DonationSingleResultInterface} from '../../../donation/interfaces/donation-single-result.interface'
import moment from 'moment'
import {
  SubmitTransactionTaqurExcel,
  TotalTabungan,
} from '../providers/transaction-management.provider'

interface TaqurTransactionState {
  transactionLoading: boolean
  transactionItems: DonationData[]
  transactionModel?: DonationResultInterface
  getTransaction: (params: GetAllDonationParam) => void
  onDelete: (id: string, params: GetAllDonationParam) => void
  totalTransactionTaqur: () => void
  totalTransactionTaqurItem: number
  totalTransactionTaqurLoading: boolean
}

export const useTaqurTransactionStore = create<TaqurTransactionState>((set, get) => ({
  transactionLoading: false,
  transactionItems: [],
  transactionModel: undefined,
  getTransaction: async (params: GetAllDonationParam) => {
    set({
      transactionItems: [],
      transactionLoading: true,
    })
    const res = await GetAllTransactionTaqur(params)
    set({transactionModel: res})
    if (res?.status) {
      if (res?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of res.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          transactionItems: res.data.items,
        })
      } else {
        set({
          transactionItems: [],
        })
      }
    } else {
      set({
        transactionItems: [],
      })
    }
    set({transactionLoading: false})
  },
  onDelete: async (id: string, params: GetAllDonationParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({transactionLoading: true})
      const response = await DeleteDonation(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getTransaction(params)
      set({transactionLoading: false})
    }
  },
  totalTransactionTaqurLoading: false,
  totalTransactionTaqurItem: 0,
  totalTransactionTaqur: async () => {
    set({
      totalTransactionTaqurLoading: true,
    })
    const response = await TotalTabungan()
    if (response?.status) {
      if (response?.data) {
        set({
          totalTransactionTaqurItem: response?.data,
        })
      } else {
        set({
          totalTransactionTaqurItem: 0,
        })
      }
    } else {
      set({
        totalTransactionTaqurItem: 0,
      })
    }
    set({totalTransactionTaqurLoading: false})
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface TaqurTransactionFormState {
  field?: DonationData
  setField?: (item: DonationData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  setFormSelectDataById?: (id: string, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
  onSubmitExcel: (form: FormData) => void
  open: boolean
  setOpen: (open: boolean) => void
}

export const useTaqurTransactionFormStore = create<TaqurTransactionFormState>((set, get) => ({
  field: undefined,
  setField: (item: DonationData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'customer_id',
        title: 'Donatur',
        placeholder: 'Donatur...',
        type: 'select-with-text',
        name: 'customer_id',
        value: {
          label: `${get().field?.customer_name ?? '-'} - ${get().field?.customer_nid ?? '-'}`,
          value: get().field?.customer_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'transfer_date',
        title: 'Waktu Transfer',
        placeholder: 'Waktu Transfer...',
        type: 'datetime-blast',
        name: 'transfer_date',
        value: moment(get().field?.transfer_date).toDate() ?? moment(Date.now()).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'program_id',
        title: 'Program',
        placeholder: 'Program...',
        type: 'select-with-text',
        name: 'program_id',
        value: {
          label: get().field?.program_title ?? '-',
          value: get().field?.program_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'payment_id',
        title: 'Payment Method',
        placeholder: 'Payment Method...',
        type: 'select-with-text',
        name: 'payment_id',
        value: {
          label: `${get().field?.payment_name ?? '-'} ${get().field?.payment_description ?? '-'}`,
          value: get().field?.payment?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'status',
        title: 'Status',
        placeholder: 'Status...',
        type: 'select',
        name: 'status',
        value: get().field?.status ?? 'waiting',
        selectData: [
          {label: 'settlement', value: 'settlement'},
          {label: 'waiting', value: 'waiting'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
        hidden: false,
      },
      {
        id: 'amount',
        title: 'Amount',
        placeholder: 'Amount...',
        type: 'currency',
        name: 'amount',
        value: get().field?.amount ?? 0,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'channel_id',
        title: 'Channel',
        placeholder: 'Channel...',
        type: 'select-with-text',
        name: 'channel_id',
        value: {
          label: 'TAQUR YAKESMA',
          value: '',
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'depositor',
        title: 'Petugas Penerima',
        placeholder: 'Petugas Penerima...',
        type: 'text',
        name: 'depositor',
        value: 'ADMIN TAQUR',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
        hidden: false,
      },
      {
        id: 'proof_of_payment',
        title: 'Bukti Transfer',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'proof_of_payment',
        value: get().field?.proof_of_payment ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
      {
        id: 'remarks',
        title: 'Note (Catatan CRM)',
        placeholder: 'Note (Catatan CRM)...',
        type: 'text-area',
        name: 'remarks',
        value: get().field?.remarks ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    form = {
      ...form,
      customer_type: reference,
      // status: 'waiting',
    }

    let res: DonationSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitDonation(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateDonation(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
  setFormSelectDataById: (id: string, selectData: SelectData[]) => {
    const forms = [...get().forms]
    const findIndex = forms.findIndex((el) => el.id === id)
    if (findIndex !== -1) {
      forms[findIndex] = {
        ...forms[findIndex],
        selectData: selectData,
      }
      set({forms: forms})
    }
  },
  open: false,
  setOpen: (open: boolean) => {
    set({open: open})
  },
  onSubmitExcel: async (form: FormData) => {
    let res = await SubmitTransactionTaqurExcel(form)
    if (res?.status) {
      toast.success(res?.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res?.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))
