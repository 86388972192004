import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {ContactData, ContactResultInterface} from '../interfaces/contact-result.interface'
import {ContactSingleResultInterface} from '../interfaces/contact-single-result.interface'

export interface GetAllContactParam {
  page?: number
  size?: number
  contact_group_id?: string
}

export const GetAllContact = async (
  params: GetAllContactParam
): Promise<ContactResultInterface> => {
  try {
    let response = await ApiCrm.get('/contacts', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ContactResultInterface = a.response?.data
    return response
  }
}

export const ShowContact = async (id: string): Promise<ContactSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/contacts/' + id)
    return response.data as ContactSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitContact = async (form: ContactData): Promise<ContactSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/contacts', form)
    return response.data as ContactSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitContactBulk = async (form: any): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.post('/contacts-bulk', form)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const UpdateContact = async (
  id: string,
  form: ContactData
): Promise<ContactSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/contacts/' + id, form)
    return response.data as ContactSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ContactSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteContact = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/contacts/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingContact = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put('/contacts-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
