import {CustomerData, CustomerResultInterface} from '../interfaces/customer-result.interface'
import {
  DeleteCustomer,
  GetAllCustomerParam,
  GetAllCustomerAffiliate,
  MoveToQuarantine,
  MoveToUnquarantine,
  SubmitCustomer,
  UpdateCustomer,
  GetCustomerByAffiliator,
  GetAllCustomerV2,
  GetTotalCustomersRelawan,
} from '../providers/customer.provider'
import {create} from 'zustand'
import {createJSONStorage, persist} from 'zustand/middleware'
import {TableHeaderInterface} from '../../../../../interfaces/table-header.interface'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {ShowFieldByReference} from '../../properties-management/providers/field.provider'
import {Field, FieldData} from '../../properties-management/interfaces/field-result.interface'
import {CustomerSingleResultInterface} from '../interfaces/customer-single-result.interface'
import {toast} from 'react-toastify'
import {
  generateFormLogic,
  generateInitValue,
  generateValueLogic,
} from '../../../../../../_metronic/helpers/generate-form-logic'
import {SubmitContactGroup} from '../../contact-group/providers/contact-group.provider'
import {ContactGroupSingleResultInterface} from '../../contact-group/interfaces/contact-group-single-result.interface'
import {CsvContact} from '../../contact/states/contact.state'
import {getUniqueListBy} from '../../../../../../_metronic/helpers/crud-helper/helpers'
import {SubmitContactBulk} from '../../contact/providers/contact.provider'

export interface CustomerState {
  customerLoading: boolean
  getCustomers: (params: GetAllCustomerParam) => void
  getCustomersAffiliate: (params: GetAllCustomerParam) => void
  customerItems: CustomerData[]
  customerSelectDatas: SelectData[]
  customerModel?: CustomerResultInterface
  customerHeaders: TableHeaderInterface[]
  customerColumns: any[]
  onDelete: (id: string, params: GetAllCustomerParam) => void
  moveToQuarantine: (id: string, params: GetAllCustomerParam) => void
  moveToUnquarantine: (id: string, params: GetAllCustomerParam) => void
  navList: NavList[]
  activeNavIndex: number
  setActiveNavIndex: (index: number) => void

  customerByAffiliatorLoading: boolean
  customerByAffiliatorItems: CustomerData[]
  customerByAffiliatorModel?: CustomerResultInterface
  getCustomerByAffiliator: (params: GetAllCustomerParam, id: string) => void

  getCustomersForRelawan: () => void
  totalCustomersRelawan: number
  totalCUstomersLoading: boolean

  chosenCustomer?: CustomerData[]
  setChosenCustomer?: (data: CustomerData[]) => void
  deleteChosenCustomer?: (id: string) => void
  isChooseAll: boolean
  setIsChooseAll?: (data: boolean) => void
  totalChosenCustomer?: number

  contactGroupName?: string
  setContactGroupName?: (data: string) => void
  submitContactGroup?: (params: GetAllCustomerParam) => Promise<void>
  processedData?: number
  processedDataPercentage?: number
  contactGroupLoading: boolean
  createContactGroupActive: boolean
  setCreateContactGroupActive: () => void
}

interface NavList {
  name: string
  reference: string
}

export const useCustomerStore = create(
  persist<CustomerState>(
    (set, get) => ({
      getCustomersForRelawan: async () => {
        set({totalCUstomersLoading: true})
        const response = await GetTotalCustomersRelawan()
        if (response.status) {
          set({totalCustomersRelawan: response.data?.total_customers ?? 0})
        } else {
          set({totalCustomersRelawan: 0})
        }
        set({totalCUstomersLoading: false})
      },
      totalCustomersRelawan: 0,
      totalCUstomersLoading: false,
      customerLoading: false,
      customerItems: [],
      customerModel: undefined,
      customerHeaders: [],
      customerColumns: [],
      customerSelectDatas: [],
      activeNavIndex: 0,
      setActiveNavIndex: (index: number) => {
        set({activeNavIndex: index})
      },
      navList: [
        {
          name: 'Retails',
          reference: 'customers',
        },
        {
          name: 'Corporate',
          reference: 'customers_corporate',
        },
        {
          name: 'Community',
          reference: 'customers_community',
        },
      ],
      setNavList: () => {},
      getAllCustomers: async () => {
        set({customerLoading: true})
      },
      getCustomers: async (params: GetAllCustomerParam) => {
        set({customerLoading: true, customerItems: [], customerSelectDatas: []})
        const propertiesRes = await ShowFieldByReference(params?.reference)
        const response = await GetAllCustomerV2(params)
        set({customerModel: response})

        let headers: TableHeaderInterface[] = []
        let columns = []
        let singleColumn = {}

        if (propertiesRes) {
          if (propertiesRes?.status) {
            if (propertiesRes?.data?.length > 0) {
              // set header
              for (const item of propertiesRes?.data) {
                if (item.fields.is_header) {
                  headers.push({
                    headerName: item.fields.title,
                    headerId: item.fields.name,
                    hidden: false,
                  })
                }
              }
              set({
                customerHeaders: headers,
              })
            }
          }
        }

        if (response) {
          if (response?.status) {
            if (response?.data?.items?.length > 0) {
              set({customerItems: response?.data?.items})

              // set select data
              let transformDatas: SelectData[] = []

              // set columns
              for (const item of response?.data?.items) {
                if (item.customer_type === 'customers_corporate') {
                  console.log(get().navList[get().activeNavIndex].reference)
                  transformDatas.push({
                    value: item?.id,
                    label: `${item?.customer_values['nama-perusahaan'] ?? ''} - ${
                      item?.customer_values['nomor-hpwa'] ??
                      item?.customer_values['nomor-hpwa-pic'] ??
                      item?.customer_values['no-telepon-perusahaan']
                    } - ${item?.customer_values['email-perusahaan'] ?? ''} - ${
                      item?.customer_values['nid'] ?? ''
                    }`,
                  })
                }
                if (item.customer_type === 'customers_community') {
                  transformDatas.push({
                    value: item?.id,
                    label: `${item?.customer_values['nama-komunitas'] ?? ''} - ${
                      item?.customer_values['nomor-hpwa']
                    } - ${item?.customer_values['email-pic'] ?? ''}`,
                  })
                }
                if (item.customer_type === 'customers') {
                  transformDatas.push({
                    value: item?.id,
                    label: `${item?.customer_values['nama-lengkap'] ?? ''} - ${
                      item?.customer_values['nomor-hpwa'] ?? ''
                    } - ${item?.customer_values['email'] ?? ''}`,
                  })
                }

                singleColumn['fieldId'] = item?.id
                for (const key in item.customer_values) {
                  if (item?.customer_values.hasOwnProperty(key)) {
                    singleColumn[key] = item?.customer_values[key]
                  }
                }
                columns.push(singleColumn)
              }
              set({
                customerSelectDatas: transformDatas,
                customerItems: response?.data?.items,
              })
            } else {
              set({customerItems: [], customerSelectDatas: []})
            }
          } else {
            set({customerItems: [], customerSelectDatas: []})
          }
        } else {
          set({customerItems: [], customerSelectDatas: []})
        }

        set({customerLoading: false})
      },
      getCustomersAffiliate: async (params: GetAllCustomerParam) => {
        set({customerLoading: true})
        const propertiesRes = await ShowFieldByReference(params.reference)
        const response = await GetAllCustomerAffiliate(params)
        set({customerModel: response})

        let headers: TableHeaderInterface[] = []
        let columns = []
        let singleColumn = {}

        if (propertiesRes) {
          if (propertiesRes.status) {
            if (propertiesRes.data.length > 0) {
              // set header
              for (const item of propertiesRes.data) {
                if (item.fields.is_header) {
                  headers.push({
                    headerName: item.fields.title,
                    headerId: item.fields.name,
                    hidden: false,
                  })
                }
              }
              headers.push({
                headerName: 'Quarantine',
                headerId: 'quarantine',
                hidden: false,
              })
              headers.push({
                headerName: 'Action',
                headerId: 'action',
                hidden: false,
              })
              set({
                customerHeaders: headers,
              })
            }
          }
        }

        if (response) {
          if (response.status) {
            if (response.data.items.length > 0) {
              set({customerItems: response.data.items})

              // set select data
              let transformDatas: SelectData[] = []

              // set columns
              for (const item of response.data.items) {
                let customerName = '',
                  customerNid = ''
                for (const iitem of item.values) {
                  if (iitem.name === 'nid') {
                    customerNid = iitem.value as string
                  }
                  if (iitem.name === 'nama-lengkap') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }
                  if (iitem.name === 'nama-perusahaan') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }
                  if (iitem.name === 'nama-komunitas') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }

                  singleColumn['fieldId'] = item.id
                  const value = item.values.find((el) => el.id === iitem.id)
                  if (value) {
                    singleColumn[value.name] = value.value
                  }
                }
                transformDatas.push({
                  value: item.id,
                  label: `${customerName} - ${customerNid}`,
                })
                columns.push(singleColumn)
              }

              set({
                customerColumns: columns,
                customerSelectDatas: transformDatas,
              })
            } else {
              set({customerItems: [], customerSelectDatas: []})
            }
          } else {
            set({customerItems: [], customerSelectDatas: []})
          }
        } else {
          set({customerItems: [], customerSelectDatas: []})
        }
        set({customerLoading: false})
      },
      onDelete: async (id: string, params: GetAllCustomerParam) => {
        const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
        if (confirm === true) {
          set({customerLoading: true})
          const response = await DeleteCustomer(id)
          if (response.status) {
            toast.success(response.message)
            get().getCustomers(params)
          } else {
            toast.error(response.message)
          }
        }
      },
      moveToQuarantine: async (id: string, params: GetAllCustomerParam) => {
        set({customerLoading: true})
        const response = await MoveToQuarantine(id)
        if (response.status) {
          toast.success(response.message)
          get().getCustomers(params)
        } else {
          toast.error(response.message)
        }
        set({customerLoading: false})
      },
      moveToUnquarantine: async (id: string, params: GetAllCustomerParam) => {
        set({customerLoading: true})
        const response = await MoveToUnquarantine(id)
        if (response.status) {
          toast.success(response.message)
          get().getCustomers(params)
        } else {
          toast.error(response.message)
        }
        set({customerLoading: false})
      },

      //Get customers
      customerByAffiliatorLoading: false,
      customerByAffiliatorItems: [],
      customerByAffiliatorModel: undefined,
      getCustomerByAffiliator: async (params: GetAllCustomerParam, id: string) => {
        set({customerByAffiliatorLoading: true})
        const propertiesRes = await ShowFieldByReference(params.reference)
        const response = await GetCustomerByAffiliator(params, id)
        set({customerByAffiliatorModel: response})

        let headers: TableHeaderInterface[] = []
        let columns = []
        let singleColumn = {}

        if (propertiesRes) {
          if (propertiesRes.status) {
            if (propertiesRes.data.length > 0) {
              // set header
              for (const item of propertiesRes.data) {
                if (item.fields.is_header) {
                  headers.push({
                    headerName: item.fields.title,
                    headerId: item.fields.name,
                    hidden: false,
                  })
                }
              }

              set({
                customerHeaders: headers,
              })
            }
          }
        }

        if (response) {
          if (response.status) {
            if (response.data.items.length > 0) {
              set({customerByAffiliatorItems: response.data.items})

              // set select data
              let transformDatas: SelectData[] = []

              // set columns
              for (const item of response.data.items) {
                let customerName = '',
                  customerNid = ''
                for (const iitem of item.values) {
                  if (iitem.name === 'nid') {
                    customerNid = iitem.value as string
                  }
                  if (iitem.name === 'nama-lengkap') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }
                  if (iitem.name === 'nama-perusahaan') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }
                  if (iitem.name === 'nama-komunitas') {
                    customerName = iitem.value as string
                    // transformDatas.push({
                    //   value: item.id,
                    //   label: iitem.value as string,
                    // })
                  }

                  singleColumn['fieldId'] = item.id
                  const value = item.values.find((el) => el.id === iitem.id)
                  if (value) {
                    singleColumn[value.name] = value.value
                  }
                }
                transformDatas.push({
                  value: item.id,
                  label: `${customerName} - ${customerNid}`,
                })
                columns.push(singleColumn)
              }
            } else {
              set({customerByAffiliatorItems: []})
            }
          } else {
            set({customerByAffiliatorItems: []})
          }
        } else {
          set({customerByAffiliatorItems: []})
        }
        set({customerByAffiliatorLoading: false})
      },

      isChooseAll: false,
      chosenCustomer: [],
      setChosenCustomer: (data: CustomerData[]) => {
        set({
          chosenCustomer: [...get().chosenCustomer, ...data],
          totalChosenCustomer: get().totalChosenCustomer + 1,
        })
      },
      deleteChosenCustomer: (id: string) => {
        let chosenCustomer = [...get().chosenCustomer]
        chosenCustomer = chosenCustomer.filter((item) => item.id !== id)
        set({chosenCustomer: chosenCustomer, totalChosenCustomer: chosenCustomer.length})
      },
      setIsChooseAll: (data: boolean) => {
        set({isChooseAll: data})
        if (data === true) {
          set({
            chosenCustomer: get().customerItems,
            totalChosenCustomer: get().customerModel?.data?.total,
          })
        } else {
          set({chosenCustomer: [], totalChosenCustomer: 0})
        }
      },
      totalChosenCustomer: 0,

      contactGroupName: '',
      setContactGroupName: (data: string) => {
        set({contactGroupName: data})
      },
      submitContactGroup: async (params: GetAllCustomerParam) => {
        set({contactGroupLoading: true})
        let form = {
          name: get().contactGroupName,
        }
        let res: ContactGroupSingleResultInterface = await SubmitContactGroup(form)
        if (res.status) {
          if (get().isChooseAll) {
            let page = 0
            let totalPage = get().customerModel?.data?.total_pages - 1
            while (page <= totalPage) {
              const response = await GetAllCustomerV2({
                page: page,
                size: 10,
                reference: params?.reference,
                search: params?.search,
                start_date: params?.start_date,
                end_date: params?.end_date,
              })
              if (response.status === true) {
                const csvContacts: CsvContact[] = []
                for (const data of response?.data?.items) {
                  csvContacts.push({
                    contact_name: data.customer_values['nama-lengkap'],
                    phone_number: data.customer_values['nomor-hpwa'],
                  })
                }
                const uniqueContacts = getUniqueListBy(csvContacts, 'phone_number')
                const form = {
                  tag_id: 0,
                  contact_group_id: res.data?.id,
                  contact_csv_data: uniqueContacts,
                }
                const resContactBulk = await SubmitContactBulk(form)
                if (resContactBulk.status === true) {
                  const processedData = get().processedData + uniqueContacts.length
                  const processedDataPercentage = (processedData / get().totalChosenCustomer) * 100
                  set({
                    processedData: processedData,
                    processedDataPercentage: processedDataPercentage,
                  })
                  page++
                  totalPage = response?.data?.total_pages - 1
                }
              }
              await new Promise((resolve) => setTimeout(resolve, 500))
            }
          } else {
            const csvContacts: CsvContact[] = []
            for (const data of get().chosenCustomer) {
              csvContacts.push({
                contact_name: data.customer_values['nama-lengkap'],
                phone_number: data.customer_values['nomor-hpwa'],
              })
            }
            const uniqueContacts = getUniqueListBy(csvContacts, 'phone_number')
            const form = {
              tag_id: 0,
              contact_group_id: res.data?.id,
              contact_csv_data: uniqueContacts,
            }
            const resContactBulk = await SubmitContactBulk(form)
            if (resContactBulk.status === true) {
              const processedData = get().processedData + uniqueContacts.length
              const processedDataPercentage = (processedData / get().totalChosenCustomer) * 100
              set({
                processedData: processedData,
                processedDataPercentage: processedDataPercentage,
              })
            }
          }
        }
        toast.success('Berhasil membuat contact group')
        set({contactGroupLoading: false})
      },
      processedData: 0,
      processedDataPercentage: 0,
      contactGroupLoading: false,
      createContactGroupActive: false,
      setCreateContactGroupActive: () => {
        set({createContactGroupActive: !get().createContactGroupActive})
      },
    }),
    {
      name: 'customer-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export interface CustomerFormState {
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam?: FormProps
  reset: () => void
  field?: CustomerData
  setField?: (field: CustomerData) => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
  fieldItems: FieldData[]
}

const formInitialState = {
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useCustomerFormStore = create<CustomerFormState>((set, get) => ({
  forms: [],
  fieldItems: [],
  field: undefined,
  setField: (field: CustomerData) => {
    set({field: field})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    let forms: FormInput[] = []
    set({forms: []})
    const response = await ShowFieldByReference(formParam.referenceTable)
    if (response) {
      if (response.data.length > 0) {
        set({fieldItems: response.data})
        const fields = get().field
        console.log('zxczxc__', fields)
        for (const item of response.data) {
          if (fields === null) {
            forms.push({
              id: item.id,
              title: item.fields.title,
              placeholder: item.fields.placeholder,
              type: item.fields.type,
              name: item.fields.name,
              value: undefined,
              selectData: item.fields.select_data,
              disabled: formParam.action === FormAction.VIEW ? true : false,
              required: item.fields.required,
            })
          } else {
            const theField = fields.customer_values[item.fields.name]

            if (theField) {
              console.log(`cxzczx ${theField}`)
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                value: generateValueLogic(item.fields.type, theField),
                selectData: item.fields.select_data,
                disabled:
                  formParam.action === FormAction.VIEW
                    ? true
                    : item.fields?.name === 'nid'
                    ? true
                    : false,
                required: item.fields.required,
              })
            } else {
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                // value: item.fields.value ? item.fields.value : {value: ''},
                value: generateInitValue(item.fields.type, item.fields.value),
                selectData: item.fields.select_data,
                disabled: formParam.action === FormAction.VIEW ? true : false,
                required: item.fields.required,
              })
            }
          }
        }
      }
      set({forms: forms, formParam: formParam})
      return
    }
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    set({formLoading: true, submitDone: false})

    if (get().fieldItems?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }
    if (get().forms?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }

    // check required field
    let requiredError = false

    for (const item of get().forms) {
      if (item.required === true && (item.value === '' || item.value === undefined)) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    let values: Field[] = []
    const fields = get().fieldItems
    for (const item of fields) {
      const fieldIndex = get().forms.findIndex((el) => el.id === item.id)
      if (fieldIndex > -1) {
        values.push({
          id: item.id,
          title: item.fields.title,
          name: item.fields.name,
          placeholder: item.fields.placeholder,
          type: item.fields.type,
          select_data: item.fields.select_data,
          required: item.fields.required,
          is_header: item.fields.is_header,
          value: generateFormLogic(item.fields.type, get().forms[fieldIndex].value),
        })
      }
    }

    const form = {
      customer_type: reference,
      values: values,
    }

    let res: CustomerSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitCustomer(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateCustomer(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))
