import {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {
  dateQueryFormatter,
  timeDateFormatter,
} from '../../../../../../_metronic/helpers/datetime-formatter'
import {generateStatusBadge} from '../../../../../../_metronic/helpers/generate-status-badge'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {FormAction} from '../../../../../interfaces/form-action-interfaces'
import {AUTH_LOCAL_STORAGE_KEY, AuthModel, useAuth} from '../../../../auth'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {
  useDonationAffiliateStore,
  useDonationAffiliateFormStore,
} from '../states/program-affiliate.state'
import DonationAffiliateModalComponent from './donation-affiliate-modal.component'
import {reference} from '@popperjs/core'
import {toast} from 'react-toastify'
import moment from 'moment'

const DonationListAffiliatorComponent = () => {
  const {
    activeNavIndex,
    navList,
    setActiveNavIndex,
    donationAffiliateLoading,
    donationAffiliateModel,
    donationAffiliateItems,
    affiliateCode,
    fundraiserId,
    getDonationAffiliate,
  } = useDonationAffiliateStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} =
    useDonationAffiliateFormStore()

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  useEffect(() => {
    getDonationAffiliate(currentUser?.data?.id, {
      page: 0,
      size: 10,
      reference: navList[activeNavIndex].reference,
      aff_code: affiliateCode,
      fundraiser_id: fundraiserId,
    })
  }, [])

  useEffect(() => {
    getDonationAffiliate(currentUser?.data?.id, {
      page: 0,
      size: 10,
      reference: navList[activeNavIndex].reference,
      aff_code: affiliateCode,
      fundraiser_id: fundraiserId,
    })
  }, [activeNavIndex])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getDonationAffiliate(currentUser?.data?.id, {
        page: 0,
        size: 10,
        aff_code: affiliateCode,
        fundraiser_id: fundraiserId,
      })
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getDonationAffiliate(currentUser?.data?.id, {
      page: selectedPage,
      size: 10,
      aff_code: affiliateCode,
      fundraiser_id: fundraiserId,
    })
  }
  return (
    <>
      <div className='card' style={{marginBottom: 10}}>
        <div className='card-header border-0'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {navList.map((item, index) => {
                return (
                  <li key={index} className='nav-item'>
                    <a
                      className={`nav-link text-active-primary me-6 ${
                        activeNavIndex === index ? 'active' : 'false'
                      }`}
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        setActiveNavIndex(index)
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      {exportLoading ? (
        <div className='alert alert-info d-flex align-items-center p-5'>
          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-info'>Report on progress</h4>
            <span>Generating your report please wait....</span>
          </div>
        </div>
      ) : null}
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>History Donation</span>
          </h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                if (!exportLoading) {
                  const tokenLocal = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
                  const token = JSON.parse(tokenLocal) as AuthModel
                  setExportLoading(true)
                  const params = {
                    company_id: currentUser?.data?.company?.id,
                    reference: undefined,
                    program_id: undefined,
                    fundraiser_id: undefined,
                    start_date: '2023-05-08',
                    end_date: dateQueryFormatter(new Date()),
                    customer_id: undefined,
                    status: undefined,
                    province_id: undefined,
                    city_id: undefined,
                    aff_code: currentUser?.data?.affiliate_code ?? undefined,
                    branch_id: undefined,
                    program_type: undefined,
                    payment_id: undefined,
                    payment_selected: undefined,
                    prospect_channel_id: undefined,
                    channel_selected: undefined,
                    invoice_code: undefined,
                  }
                  fetch(
                    `${
                      process.env.REACT_APP_API_PROGRAM_URL
                    }/export-donations?${new URLSearchParams(params)}`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type':
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        Authorization: `Bearer ${token?.data?.accessToken}`,
                      },
                    }
                  )
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(new Blob([blob]))

                      const link = document.createElement('a')
                      link.href = url
                      link.download = `Export_Donation_${Date.now()}.xlsx`

                      document.body.appendChild(link)

                      link.click()

                      link.parentNode.removeChild(link)
                      setExportLoading(false)
                    })
                    .catch(() => {
                      setExportLoading(false)
                    })
                } else {
                  toast.error('Exporting in progress...')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Export Excel
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {donationAffiliateLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <tr>
                  <th>Donatur</th>
                  <th>Program</th>
                  <th>Amount</th>
                  <th>Channel</th>
                  <th>Profiling</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>E-Kwitansi</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {donationAffiliateItems.length === 0 ? (
                  <tr>
                    <td colSpan={10}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  donationAffiliateItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/crm/customer-management/customer/${item.customer_id}`)
                            }}
                          >
                            {item?.customer_values
                              ? item.customer_values['nama-lengkap'] ||
                                item.customer_values['nama-perusahaan'] ||
                                item.customer_values['nama-komunitas']
                              : '-'}
                          </span>

                          <div className='d-flex flex-column'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary mb-1'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate(
                                  `/apps/crm/customer-management/customer/${item.customer_id}`
                                )
                              }}
                            >
                              {/* {item.customer_name ?? '-'} */}
                            </a>
                            <span>
                              {item?.customer_values
                                ? item.customer_values['email'] ||
                                  item.customer_values['email-pic'] ||
                                  item.customer_values['email-komunitas']
                                : '-'}
                            </span>
                            <span>
                              {' '}
                              {item?.customer_values
                                ? item.customer_values['no-telp-komunitas'] ||
                                  item.customer_values['nomor-hpwa'] ||
                                  item.customer_values['nomor-hpwa-pic'] ||
                                  item.customer_values['no-perusahaan']
                                : '-'}{' '}
                            </span>
                            <span>{item?.customer_values ? item.customer_values['nid'] : '-'}</span>
                          </div>
                        </td>
                        <td>{item.program_title}</td>
                        <td>{generateCurrencyFormat(item.amount)}</td>
                        <td>{item?.channel_name}</td>
                        <td>{item?.customer_values['profiling'] ?? '-'}</td>
                        <td>{timeDateFormatter(Date.parse(item.created_at.toString()))}</td>
                        <td>{generateStatusBadge(item.status)}</td>

                        <td>
                          <span
                            className='badge badge-success'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              navigate(
                                `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                              )
                            }}
                          >
                            E-Kwitansi
                          </span>
                        </td>

                        <td className='text-end'>
                          {currentUser?.data?.role?.slug === 'superadmin' ||
                          currentUser?.data?.role?.slug === 'admin' ? (
                            <>
                              <a
                                href={`#`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={(e) => {
                                  e.preventDefault()
                                  setField(item)
                                  generateForms({
                                    title: 'Update Donation',
                                    subtitle: 'Update Donation',
                                    action: FormAction.UPDATE,
                                    data: item,
                                    id: item.id,
                                  })
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                // onClick={(e) => {
                                //   e.preventDefault()
                                //   onDelete(item.id, {
                                //     page: 0,
                                //     size: 10,
                                //   })
                                // }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={donationAffiliateModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <DonationAffiliateModalComponent />}
    </>
  )
}

export default DonationListAffiliatorComponent
