import {useEffect} from 'react'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import MiniCardComponent from '../../../auth/affiliator-management/components/mini-card-dashboard.component'
import DashboardCardAffiliatorComponent from '../../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import {useDashboardAffiliateStore} from '../states/program-affiliate.state'

import {useAuth} from '../../../../auth'
import useCopyToClipboard from './copytToClipBoard'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useKorcaStore} from '../../../crm/korca-management/states/korca-management.state'
import DonationListAffiliatorComponent from './donation-affiliator-list.component'
import GenerateLinkListWrapper from './genarete-link-list.component'
import {useCustomerStore} from '../../../crm/customer-management/states/customer.state'
import {useAffiliatorCompanyStore} from '../../../auth/affiliator-management/states/affiliator-state'
import {useGroupWorkFormStore} from '../../../crm/korca-management/states/korca-detail.state'

const DashboardAffiliatorComponent = () => {
  const [value, copy] = useCopyToClipboard()
  const {totalDonationByKorcaLoading, totalDonationByKorcaTransferLoading} =
    useDashboardAffiliateStore()

  const {getKorcaGroup, korcaGroupLoading, korcaGroupModel} = useKorcaStore()
  const {
    getKorcaDetail,
    korcaDetailModel,
    getKorcaTotalRelawan,
    korcaTotalRelawan,
    korcaTotalRelawanLoading,
  } = useGroupWorkFormStore()

  const {currentUser} = useAuth()
  const {totalCustomersRelawan, totalCUstomersLoading} = useCustomerStore()
  const {totalDonationMember, totalDonationMemberLoading} = useAffiliatorCompanyStore()

  const {
    getTotalDonationAffiliateV2,
    totalDonationAffiliateV2Items,
    totalDonationAffiliateV2Loading,
  } = useDashboardAffiliateStore()

  useEffect(() => {
    if (currentUser?.data?.role_name === 'KORCA') {
      getTotalDonationAffiliateV2(currentUser?.data?.id, true)
    } else {
      getTotalDonationAffiliateV2(currentUser?.data?.id, false)
    }

    getKorcaTotalRelawan(currentUser?.data?.id)
    if (korcaGroupModel?.data?.id?.length > 0) {
      getKorcaDetail(korcaGroupModel?.data?.id)
    }
  }, [korcaGroupModel])

  useEffect(() => {
    // getTotalDonationByKorca()
    // getTotalDonationByKorcaTunai()
    // getTotalDonationByKorcaTransfer()
    console.log(korcaDetailModel)
  }, [korcaDetailModel])

  useEffect(() => {
    if (currentUser?.data?.id?.length > 0) {
      getKorcaGroup(currentUser?.data?.id)
      // getKorcaDetail(currentUser?.data?.id)
    }
  }, [currentUser])

  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5'>
        {currentUser?.data?.role?.slug === 'korca' ? (
          <DashboardCardAffiliatorComponent
            title='Total Donation Seluruh Group'
            color='success'
            value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
            subtitle='Total donasi yang terkumpul'
            loading={totalDonationByKorcaLoading}
          />
        ) : (
          <DashboardCardAffiliatorComponent
            title='Total Donation '
            color='success'
            value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
            subtitle='Total donasi yang terkumpul'
            loading={totalDonationAffiliateV2Loading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <DashboardCardAffiliatorComponent
            title='Total Group'
            color='warning'
            value={korcaDetailModel?.data?.group_work?.length ?? 0}
            subtitle='Total donatur'
            loading={korcaGroupLoading}
          />
        ) : (
          <DashboardCardAffiliatorComponent
            title='Total Donatur'
            color='warning'
            value={totalCustomersRelawan}
            subtitle='Total donatur'
            loading={totalCUstomersLoading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Transfer'
            color='primary'
            value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
            icon={
              <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
            }
            loading={totalDonationByKorcaTransferLoading}
          />
        ) : (
          <MiniCardComponent
            title='Donation By CRM'
            color='primary'
            value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
            icon={
              <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
            }
            loading={totalDonationAffiliateV2Loading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Akumulasi Hak Salur/Komisi'
            color='warning'
            value={generateCurrencyFormat((totalDonationAffiliateV2Items * 5) / 100 ?? 0)}
            icon={
              <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
            }
            loading={totalDonationByKorcaTransferLoading}
          />
        ) : (
          <MiniCardComponent
            title='Donation Via Platform'
            color='warning'
            value={generateCurrencyFormat(totalDonationAffiliateV2Items ?? 0)}
            icon={<i className={`bi bi-cash-coin text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationAffiliateV2Loading}
          />
        )}

        {currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Total Relawan Aktif'
            color='danger'
            // value={generateCurrencyFormat(
            //   totalDonationByKorca - totalDonationByKorcaTransfer - totalDonationByKorcaTunai ??
            //     0 ??
            //     0
            // )}
            value={generateCurrencyFormat(korcaTotalRelawan)}
            icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={korcaTotalRelawanLoading}
          />
        ) : (
          <MiniCardComponent
            title='Affiliate'
            color='danger'
            value={generateCurrencyFormat(totalDonationMember ?? 0)}
            icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationMemberLoading}
          />
        )}

        <MiniCardComponent
          title='Target Donasi Tahunan'
          color='success'
          value={generateCurrencyFormat(300000000)}
          icon={
            <i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={totalDonationAffiliateV2Loading}
        />
        {currentUser?.data?.role?.slug === 'korca' ? (
          ''
        ) : (
          <div className='card-toolbar text-end'>
            <button
              type='button'
              className='btn btn-danger'
              style={{
                marginLeft: 5,
              }}
              onClick={() => {
                copy(
                  `${currentUser?.data?.company?.platform_donation}?affiliate_code=${currentUser.data.affiliate_code}`
                )
                if (value) {
                  toast.success('copied !')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create Affiliate Link
            </button>
          </div>
        )}
      </div>
      {currentUser?.data?.role?.slug === 'korca' ? null : (
        <div className='mb-5'>
          <GenerateLinkListWrapper />
        </div>
      )}

      {currentUser?.data?.role?.slug === 'korca' ? null : (
        <div className='mb-5'>
          <DonationListAffiliatorComponent />
        </div>
      )}
    </>
  )
}

export default DashboardAffiliatorComponent
