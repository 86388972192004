import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMainBendahara = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/member-management/group-member'
        title='Bendahara'
        hasBullet={true}
      />
    </>
  )
}

export default SidebarMenuMainBendahara
