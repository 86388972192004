import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {
  ProspectChannelData,
  ProspectChannelResultInterface,
} from '../interfaces/prospect-channel-result.interface'
import {ProspectChannelSingleResultInterface} from '../interfaces/prospect-channel-single-result.interface'
import {
  GetAllProspectChannelParam,
  GetAllProspectChannel,
  DeleteProspectChannel,
  SubmitProspectChannel,
  UpdateProspectChannel,
} from '../providers/prospect-channel.provider'

interface ProspectChannelState {
  prospectChannelLoading: boolean
  prospectChannelItems: ProspectChannelData[]
  prospectChannelSelectDatas: SelectData[]
  prospectChannelModel?: ProspectChannelResultInterface
  getProspectChannels: (params: GetAllProspectChannelParam) => void
  onDelete: (id: string, params: GetAllProspectChannelParam) => void
}

export const useProspectChannelStore = create<ProspectChannelState>((set, get) => ({
  prospectChannelLoading: false,
  prospectChannelItems: [],
  prospectChannelSelectDatas: [],
  prospectChannelModel: undefined,
  getProspectChannels: async (params: GetAllProspectChannelParam) => {
    set({prospectChannelLoading: true})
    const response = await GetAllProspectChannel(params)
    set({prospectChannelModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          prospectChannelItems: response.data.items,
          prospectChannelSelectDatas: transformDatas,
        })
      } else {
        set({
          prospectChannelItems: [],
          prospectChannelSelectDatas: [],
        })
      }
    } else {
      set({
        prospectChannelItems: [],
        prospectChannelSelectDatas: [],
      })
    }
    set({prospectChannelLoading: false})
  },
  onDelete: async (id: string, params: GetAllProspectChannelParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({prospectChannelLoading: true})
      const response = await DeleteProspectChannel(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getProspectChannels(params)
      set({prospectChannelLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ProspectChannelFormState {
  field?: ProspectChannelData
  setField?: (item: ProspectChannelData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useProspectChannelFormStore = create<ProspectChannelFormState>((set, get) => ({
  field: undefined,
  setField: (item: ProspectChannelData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'name',
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: get().field?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: ProspectChannelSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitProspectChannel(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateProspectChannel(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
