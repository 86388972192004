import {Suspense, useEffect, useRef, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {getUserByTokenV2} from './modules/auth/core/_requests'

const App = () => {
  const location = useLocation()
  const {logout, setCurrentUser, saveAuth} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    console.log(showSplashScreen)
    const requestUser = async (token: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByTokenV2(token)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (location) {
      if (location.search) {
        if (location.search.includes('?token')) {
          const token = location.search?.replaceAll('?token=', '')
          if (token?.length > 0) {
            saveAuth({
              status: true,
              message: 'authorized',
              data: {
                accessToken: token,
                refreshToken: token,
              },
            })
            requestUser(token)
          }
        }
      }
    }
  }, [location])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
