import {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {KTCard} from '../../../../../../_metronic/helpers'
import {
  dateQueryFormatter,
  timeDateFormatter,
} from '../../../../../../_metronic/helpers/datetime-formatter'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {useClientStore} from '../states/client.states'
import {useNavigate} from 'react-router-dom'
import {InputDatePicker} from '../../../../forms/components/input.component'
import {toast} from 'react-toastify'

const ClientListComponent = () => {
  const {getClient, clientItems, clientLoading, clientModel} = useClientStore()
  const [endDate, setEndDate] = useState<Date>(undefined)
  const [startDate, setStartDate] = useState<Date>(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    getClient({
      page: 0,
      size: 10,
      start_date: '',
      end_date: '',
    })
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getClient({
      page: selectedPage,
      size: 10,
      start_date: startDate === undefined ? undefined : dateQueryFormatter(startDate),
      end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
    })
  }
  return (
    <>
      <div className='col-sm-12 col-xl-12 mb-xl-12 '>
        <div className='py-5'>
          <div className='rounded border p-5' style={{backgroundColor: 'white'}}>
            <div className='col-12'>
              <div className='row'>
                <div className='col-2'>
                  <InputDatePicker
                    id={'start_date'}
                    title={'Start Date'}
                    name={'start_date'}
                    type={'date'}
                    value={startDate}
                    required={false}
                    onChangeDate={(e) => setStartDate(e)}
                  />
                </div>
                <div className='col-2'>
                  <InputDatePicker
                    id={'end_date'}
                    title={'End Date'}
                    name={'end_date'}
                    type={'date'}
                    value={endDate}
                    required={false}
                    onChangeDate={(e) => setEndDate(e)}
                  />
                </div>
                <div className='p-5'>
                  <div className='col-4'>
                    <button
                      className='btn btn-primary me-5'
                      onClick={(e) => {
                        getClient({
                          page: 0,
                          size: 10,
                          start_date:
                            startDate === undefined ? undefined : dateQueryFormatter(startDate),
                          end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
                        })
                      }}
                    >
                      Search
                    </button>
                    <button
                      className='btn btn-primary'
                      onClick={(e) => {
                        setStartDate(undefined)
                        setEndDate(undefined)
                        getClient({
                          page: 0,
                          size: 10,
                          start_date: undefined,
                          end_date: undefined,
                        })
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <KTCard>
          <div className='card card-xxl-stretch mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Client List</span>
              </h3>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                {clientLoading ? <LoadingComponent /> : <></>}
                <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                  <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <tr>
                      <th>Client</th>
                      <th>Join Date</th>
                      <th>Total Donation</th>
                      <th>Platform Sharing Fee (2.5%)</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                    {clientItems.length === 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No Properties Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      clientItems?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{timeDateFormatter(Date.parse(item.join_date.toString()))}</td>
                            <td>{generateCurrencyFormat(item.total_donation)}</td>
                            <td>{generateCurrencyFormat(item.sharing_profit)}</td>
                            <td>
                              <span
                                className='badge badge-success'
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  // navigate(
                                  //   `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                                  // )
                                  toast.warn('Under Construction')
                                }}
                              >
                                Detail
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='card-footer'>
              <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={'...'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageCount={clientModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={'pagination pagination-sm m-0 float-right'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </KTCard>
      </div>
    </>
  )
}

export default ClientListComponent
