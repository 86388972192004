import {Route, Routes, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../auth'
import {AffiliatorListByDistrictWrapper} from '../../../auth/affiliator-management/components/affiliator-district-list.component'
import {KorcaMemberListWrapper} from '../../../crm/korca-management/components/korca-group-detail.component'
import DashboardAffiliatorComponent from '../components/dashboard-affiliator.component'

const donationAffiliateBreadcrumbs: Array<PageLink> = [
  {
    title: 'Program Affiliate',
    path: '/apps/program-affiliate/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DonationAffiliateDashboardPage = () => {
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route>
        <Route
          path='dashboard'
          element={
            <>
              <PageTitle breadcrumbs={donationAffiliateBreadcrumbs}>
                {currentUser?.data?.role?.slug === 'korca'
                  ? ' Dashboar Korca'
                  : ' Dashboar Program Affiliate'}
              </PageTitle>
              <DashboardAffiliatorComponent />

              {
                currentUser?.data?.role?.slug === 'korca' ? (
                  // <CustomerKorcaListWrapper />
                  <AffiliatorListByDistrictWrapper />
                ) : null
                // <CustomerAffiliateListComponent />
                // <CustomerAffiliatorListWrapper />
              }

              {currentUser?.data?.role?.slug === 'korca' ? (
                <div className='mb-5 mt-5'>
                  <KorcaMemberListWrapper />{' '}
                </div>
              ) : (
                ''
              )}
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/program-affiliate/dashboard' />} />
    </Routes>
  )
}

export default DonationAffiliateDashboardPage
