import {KTCard} from '../../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {useGroupWorkFormStore} from '../states/korca-detail.state'

const KorcaMemberListComponent = () => {
  const {groupWork, groupWorkOri} = useGroupWorkFormStore()
  const navigate = useNavigate()

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Groups</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Korca Member</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {/* {groupLoading ? <LoadingComponent /> : <></>} */}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Name</th>
                  <th>Total Donation</th>
                  <th className='text-end'>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {groupWorkOri?.length === 0 || groupWorkOri == null ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  groupWorkOri.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.group_name}</td>
                        <td>
                          {generateCurrencyFormat(
                            groupWork.find((el) => el.id === item.id)?.total_donations ?? 0
                          )}
                        </td>

                        <td className='text-end'>
                          <button
                            className='btn btn-success'
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/group-management/group-detail/${item.id}`)
                            }}
                          >
                            Detail
                          </button>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}
const KorcaMemberListWrapper = () => {
  return (
    <>
      <KorcaMemberListComponent />
    </>
  )
}

export {KorcaMemberListWrapper}
