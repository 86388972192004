import ApexCharts from 'apexcharts'
import {useState, useEffect} from 'react'

import Chart from 'react-apexcharts'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {useDashboardStore} from '../../../dashboard/states/dashboard.state'

const DonationSegmentChart = () => {
  const {getCustomerSegment, customerSegmentLoading, customerSegments} = useDashboardStore()
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({})
  const [series, setSeries] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])

  useEffect(() => {
    console.log(labels)
    getCustomerSegment()
  }, [])

  useEffect(() => {
    if (customerSegments?.length > 0) {
      setSeries(customerSegments?.map((item) => item.total))
      setLabels(customerSegments?.map((item) => item.segment))
      setOptions({
        labels: customerSegments?.map((item) => item.segment),
      })
    }
  }, [customerSegments])

  return (
    <div className='col-xxl-6 col-sm-6 col-md-6'>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Donation Segmen</span>
            <span className='text-muted fw-semibold fs-7'>Jumlah Donasi setiap segmen</span>
          </h3>
        </div>

        {/* begin::Body */}
        <div className='card-body'>
          {customerSegmentLoading ? <LoadingComponent /> : <></>}
          {/* begin::Chart */}
          <Chart options={options} series={series} type='donut' width='380' />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default DonationSegmentChart
