import {useEffect} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import FormsComponent from '../../../../../forms/components/forms.component'
import {useTaqurTransactionFormStore} from '../states/transaction-management.state'
import {useStudentStore} from '../../student-management/states/student-management.state'
import {useProgramStore} from '../../../program/states/program.state'
import {usePaymentStore} from '../../../payment/states/payment.state'

const TransactionModalComponent = () => {
  const {getStudents, studentSelectDatas} = useStudentStore()
  const {setFormSelectData} = useTaqurTransactionFormStore()
  const {getPrograms, programSelectDatas} = useProgramStore()
  const {getPayments, paymentSelectDatas} = usePaymentStore()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getStudents({
      page: 0,
      size: 1000,
    })
    getPrograms({
      page: 0,
      size: 100000,
      program_type: 'Taqur Yakesma',
    })
    getPayments({
      page: 0,
      size: 100000,
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (studentSelectDatas?.length > 0) {
      setFormSelectData(0, studentSelectDatas)
    }
  }, [studentSelectDatas])

  useEffect(() => {
    if (programSelectDatas?.length > 0) {
      setFormSelectData(2, programSelectDatas)
    }
  }, [programSelectDatas])
  useEffect(() => {
    if (paymentSelectDatas?.length > 0) {
      setFormSelectData(3, paymentSelectDatas)
    }
  }, [paymentSelectDatas])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <TransactionModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <TransactionModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TransactionModalHeader = () => {
  const {setField, formParam} = useTaqurTransactionFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const TransactionModalFormWrapper = () => {
  const {forms, setForms, setField, onSubmit} = useTaqurTransactionFormStore()

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <FormsComponent forms={forms} setForms={setForms} uploadFileCb={(e) => console.log(e)} />
        </div>

        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => setField(undefined)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              onSubmit('taqur_yakesma')
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default TransactionModalComponent
