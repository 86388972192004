import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {FieldData, FieldResultInterface} from '../interfaces/field-result.interface'
import {FieldSingleResultInterface} from '../interfaces/field-single-result.interface'

export interface GetAllFieldParam {
  page?: number
  size?: number
}

export const GetAllField = async (params: GetAllFieldParam): Promise<FieldResultInterface> => {
  try {
    let response = await ApiCrm.get('/fields', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: FieldResultInterface = a.response?.data
    return response
  }
}

export const ShowField = async (id: string): Promise<FieldSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/fields/' + id)
    return response.data as FieldSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: FieldSingleResultInterface = a.response?.data
    return response
  }
}

export const ShowFieldByReference = async (reference: string): Promise<FieldResultInterface> => {
  try {
    let response = await ApiCrm.get('/fields-by-reference', {
      params: {
        reference_table: reference,
      },
    })
    return response.data as FieldResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: FieldResultInterface = a.response?.data
    return response
  }
}

export const SubmitField = async (form: FieldData): Promise<FieldSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/fields', form)
    return response.data as FieldSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: FieldSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateField = async (
  id: string,
  form: FieldData
): Promise<FieldSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/fields/' + id, form)
    return response.data as FieldSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: FieldSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteField = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/fields/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingField = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put('/fields-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
