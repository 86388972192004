import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {ProgramCategorySingleResultInterface} from '../interfaces/program-category-single-result.interface'
import {
  ProgramCategoryResultInterface,
  ProgramCategoryData,
} from '../interfaces/program-cateogyr-result.interface'

export interface GetAllProgramCategoryParam {
  page?: number
  size?: number
  pipeline_id?: string
  stage_id?: string
  query?: string
  search?: string
}

export const GetAllProgramCategory = async (
  params: GetAllProgramCategoryParam
): Promise<ProgramCategoryResultInterface> => {
  try {
    let response = await ApiProgram.get('/program-category', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramCategoryResultInterface = a.response?.data
    return response
  }
}

export const ShowProgramCategory = async (
  id: string
): Promise<ProgramCategorySingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/program-category/' + id)
    return response.data as ProgramCategorySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramCategorySingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitProgramCategory = async (
  form: ProgramCategoryData
): Promise<ProgramCategorySingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/program-category', form)
    return response.data as ProgramCategorySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramCategorySingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateProgramCategory = async (
  id: string,
  form: ProgramCategoryData
): Promise<ProgramCategorySingleResultInterface> => {
  try {
    let response = await ApiProgram.put('/program-category/' + id, form)
    return response.data as ProgramCategorySingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramCategorySingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteProgramCategory = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/program-category/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingProgramCategory = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.put('/program-category-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
