interface MiniCardComponentProps {
  title?: string
  subtitle?: string
  color?: string
  value?: any
  icon?: React.ReactNode
  loading?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MiniCardComponent: React.FC<MiniCardComponentProps> = ({
  title,
  color,
  value,
  icon,
  loading = false,
  className = 'col-sm-4 col-xl-3',
  onClick,
}) => {
  return (
    <div className={`${className} cursor-pointer`} onClick={onClick}>
      <div className={`card h-lg-100 w-100 bg-${color}`}>
        <div className='card-body d-flex justify-content-between  align-items-start flex-column '>
          <div className='d-flex flex-column'>
            <div className='m-0'>
              <span className='fw-bold fs-5 text-gray-800'>{title}</span>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {icon}
              {loading ? (
                <span className={`spinner-border spinner-border-lg text-${color}`}></span>
              ) : (
                <span className={`fw-bold fs-3 lh-1 text-white`}>{value}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniCardComponent
