interface DashboardCardAffiliatorComponentProps {
  title?: string
  subtitle?: string
  color?: string
  value?: any
  icon?: React.ReactNode
  loading?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const DashboardCardAffiliatorComponent: React.FC<DashboardCardAffiliatorComponentProps> = ({
  title,
  color,
  value,
  icon,
  subtitle,
  onClick,
  loading = false,
  className = 'col-sm-6 col-xl-6',
}) => {
  return (
    <div
      className={className}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div className='card h-lg-100'>
        <div className='card-body d-flex justify-content-between align-items-start flex-column'>
          <div className='d-flex flex-column'>
            <div className='m-0'>
              <span className='fw-bold fs-2 text-gray-800'>{title}</span>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {icon}
              {loading ? (
                <span className={`spinner-border spinner-border-lg text-${color}`}></span>
              ) : (
                <span className={`fw-bold fs-3x lh-1 ls-n2 text-${color}`}>{value}</span>
              )}
            </div>
          </div>

          <div className='mt-3'>
            <span className='fw-semibold fs-6 text-gray-400'>{subtitle}</span>
          </div>
        </div>
        <span
          className={`bullet-custom position-absolute bottom-0 w-100 h-4px bg-${color}`}
          style={{
            borderEndEndRadius: '0.625rem',
            borderBottomLeftRadius: '0.625rem',
          }}
        ></span>
      </div>
    </div>
  )
}

export default DashboardCardAffiliatorComponent
