import {useEffect, useState} from 'react'
import {
  yearFormatter,
  monthFormatter,
  dateTimeFormatter,
} from '../../../../../_metronic/helpers/datetime-formatter'
import {LoadingComponent} from '../../crm/properties-management/components/loading.component'
import {useDashboardStore} from '../states/dashboard.state'
import Chart from 'react-apexcharts'

interface SeriesData {
  name: string
  data: number[]
}

const DonationPerformanceChannelChartComponent = () => {
  const {getDonationChannel, donationChannels, donationChannelLoading} = useDashboardStore()
  const [series, setSeries] = useState<SeriesData[]>([])
  const [periode, setPeriode] = useState<string>('year')
  const [periodes, setPeriodes] = useState<string[]>([])
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({})

  useEffect(() => {
    console.log(periodes)
  }, [])
  useEffect(() => {
    getDonationChannel(periode)
  }, [periode])

  useEffect(() => {
    console.log(options)
  }, [options])

  useEffect(() => {
    if (donationChannels?.length > 0) {
      const seriesData: SeriesData[] = []
      const thePeriodes: string[] = []

      for (const item of donationChannels) {
        // check is data exist
        if (periode === 'year') {
          const year = yearFormatter(Date.parse(item.periode.toString()))
          const ind = thePeriodes.findIndex((e) => e === year)
          if (ind === -1) {
            thePeriodes.push(year)
          }
        } else if (periode === 'month') {
          const month = monthFormatter(Date.parse(item.periode.toString()))
          const ind = thePeriodes.findIndex((e) => e === month)
          if (ind === -1) {
            thePeriodes.push(month)
          }
        } else if (periode === 'week') {
          const week = dateTimeFormatter(Date.parse(item.periode.toString()))
          const ind = thePeriodes.findIndex((e) => e === week)
          if (ind === -1) {
            thePeriodes.push(week)
          }
        } else if (periode === 'day') {
          const day = dateTimeFormatter(Date.parse(item.periode.toString()))
          const ind = thePeriodes.findIndex((e) => e === day)
          if (ind === -1) {
            thePeriodes.push(day)
          }
        }
      }

      for (const item of donationChannels) {
        // check is data exist
        const ind = seriesData.findIndex((e) => e.name === item.channel_name)
        if (ind === -1) {
          seriesData.push({
            name: item.channel_name,
            data: [],
          })
        } else {
          // seriesData[ind]['data'].push(item.total);
        }
      }

      for (const item of thePeriodes) {
        // check is data exist
        for (const [k, v] of seriesData.entries()) {
          if (periode === 'year') {
            const ind = donationChannels.findIndex(
              (e) =>
                e.channel_name === v.name &&
                yearFormatter(Date.parse(e.periode.toString())) === item
            )
            if (ind === -1) {
              seriesData[k].data.push(0)
            } else {
              seriesData[k].data.push(donationChannels[ind].total)
            }
          } else if (periode === 'month') {
            const ind = donationChannels.findIndex(
              (e) =>
                e.channel_name === v.name &&
                monthFormatter(Date.parse(e.periode.toString())) === item
            )
            if (ind === -1) {
              seriesData[k].data.push(0)
            } else {
              seriesData[k].data.push(donationChannels[ind].total)
            }
          } else if (periode === 'week') {
            const ind = donationChannels.findIndex(
              (e) =>
                e.channel_name === v.name &&
                dateTimeFormatter(Date.parse(e.periode.toString())) === item
            )
            if (ind === -1) {
              seriesData[k].data.push(0)
            } else {
              seriesData[k].data.push(donationChannels[ind].total)
            }
          } else if (periode === 'day') {
            const ind = donationChannels.findIndex(
              (e) =>
                e.channel_name === v.name &&
                dateTimeFormatter(Date.parse(e.periode.toString())) === item
            )
            if (ind === -1) {
              seriesData[k].data.push(0)
            } else {
              seriesData[k].data.push(donationChannels[ind].total)
            }
          }
        }
      }

      setSeries(seriesData)
      setPeriodes(thePeriodes)
      setOptions({
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: 'Performa Donasi tiap channel',
        },
        xaxis: {
          categories: thePeriodes,
          labels: {
            formatter: function (val) {
              return val
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toString()
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      })
    }
  }, [donationChannels, periode])

  return (
    <div className='col-xxl-12'>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Performa Donasi Tiap Channel</span>
            <span className='text-muted fw-semibold fs-7'>Performa Donasi Tiap Channel</span>
          </h3>

          {/* begin::Toolbar */}
          <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                periode === 'year' ? 'active' : ''
              } px-4 me-1`}
              id='kt_charts_widget_3_year_btn'
              onClick={(e) => {
                e.preventDefault()
                setPeriode(undefined)
                setPeriode('year')
              }}
            >
              Year
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                periode === 'month' ? 'active' : ''
              } px-4 me-1`}
              id='kt_charts_widget_3_month_btn'
              onClick={(e) => {
                e.preventDefault()
                setPeriode(undefined)
                setPeriode('month')
              }}
            >
              Month
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                periode === 'week' ? 'active' : ''
              } px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={(e) => {
                e.preventDefault()
                setPeriode('week')
              }}
            >
              Week
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                periode === 'day' ? 'active' : ''
              } px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={(e) => {
                e.preventDefault()
                setPeriode('day')
              }}
            >
              Day
            </a>
          </div>
          {/* end::Toolbar */}
        </div>

        {/* begin::Body */}
        <div className='card-body'>
          {donationChannelLoading ? (
            <LoadingComponent />
          ) : (
            <Chart options={options} series={series} type='bar' height='350' />
          )}
          {/* begin::Chart */}

          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

// function getChartOptions(height: number, donationChannels: DonaitonChannelData[])

export default DonationPerformanceChannelChartComponent
