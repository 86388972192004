import {createContext, useEffect} from 'react'
export type TotalDonationContextState = {
  totalDonation?: number
}

export const TotalDonationMemberContext = createContext<TotalDonationContextState | null>(null)

interface TotalDonationContextProviderProps {
  userId: string
  children: React.ReactNode
}

export const TotalDonationMemberContextProvider = ({
  userId,
  children,
}: TotalDonationContextProviderProps) => {
  // const [totalDonation, setTotalDonation] = useState<number>()
  const totalDonation = 0

  const getTotal = async (userId: string) => {
    if (userId !== '') {
      // const affiliate = await GetTotalDonationAffiliator(userId)
    }
  }

  useEffect(() => {
    getTotal(userId)
  }, [userId])

  return (
    <TotalDonationMemberContext.Provider
      value={{
        totalDonation,
      }}
    >
      {children}
    </TotalDonationMemberContext.Provider>
  )
}
