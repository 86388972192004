import {Navigate, Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../auth'
import {
  useDashboardAffiliateStore,
  useDonationAffiliateStore,
} from '../../program/program-affiliate/states/program-affiliate.state'
import {useEffect, useState} from 'react'
import DashboardCardAffiliatorComponent from '../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import {KTCard} from '../../../../../_metronic/helpers'
import {LoadingComponent} from '../../crm/properties-management/components/loading.component'
import {useDonationStore} from '../../program/donation/states/donation.state'
import ReactPaginate from 'react-paginate'
import {timeDateFormatter} from '../../../../../_metronic/helpers/datetime-formatter'
import {generateStatusBadge} from '../../../../../_metronic/helpers/generate-status-badge'

const dashboardMitraLembagaBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard Mitra',
    path: '/apps/mitra-lembaga-dashboard/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardMitraLembagaScreen = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dashboard'
          element={
            <>
              <DashboardMitraLembagaComponent />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/mitra-lembaga-dashboard/dashboard' />} />
    </Routes>
  )
}

const DashboardMitraLembagaComponent = () => {
  const {currentUser} = useAuth()

  const {
    totalDonationByMitraLembaga,
    totalDonationByMitraLembagaLoading,
    getTotalDonationByMitraLembaga,
    getTotalDonationSuccessByMitraLembaga,
    totalDonationSuccessByMitraLembaga,
    totalDonationSuccessByMitraLembagaLoading,
    getTotalCustomerByMitraLembaga,
    totalCustomerByMitraLembaga,
    totalCustomerByMitraLembagaLoading,
  } = useDashboardAffiliateStore()

  const {getDonation, donationModel, donationItems, donationLoading} = useDonationStore()
  const {getDonationAffiliate} = useDonationAffiliateStore()
  const navigate = useNavigate()
  const [activeMenu, setActiveMenu] = useState<string>('dashboard')

  useEffect(() => {
    getTotalDonationByMitraLembaga()
  }, [])

  useEffect(() => {
    getTotalDonationSuccessByMitraLembaga()
  }, [])

  useEffect(() => {
    getTotalCustomerByMitraLembaga()
  }, [])

  useEffect(() => {
    if (totalDonationByMitraLembaga !== undefined) {
      getDonationAffiliate(currentUser?.data?.id, {
        page: 0,
        size: 10,
      })
    }
  }, [totalDonationByMitraLembaga])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getDonation({
      page: selectedPage,
      size: 10,
      reference: 'customers',
      start_date: '2023-01-01',
      end_date: '2023-12-31',
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardMitraLembagaBreadcrumbs}>
        {`DASHBOARD - ${currentUser?.data?.full_name}`}
      </PageTitle>
      <div className='row gy-5 g-xl-8 mb-5'>
        <DashboardCardAffiliatorComponent
          title='Jumlah Donasi'
          color='success'
          value={totalDonationSuccessByMitraLembaga?.data}
          subtitle='Jumlah Donasi'
          loading={totalDonationSuccessByMitraLembagaLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('donation')
          }}
        />
        <DashboardCardAffiliatorComponent
          title='Donasi Terkumpul'
          color='success'
          value={generateCurrencyFormat(totalDonationByMitraLembaga?.data)}
          subtitle='Donasi Terkumpul'
          loading={totalDonationByMitraLembagaLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('donation')
          }}
        />
        <DashboardCardAffiliatorComponent
          title='Jumlah Donatur'
          color='success'
          value={totalCustomerByMitraLembaga?.data}
          subtitle='Jumlah Donatur'
          loading={totalCustomerByMitraLembagaLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('dashboard')
          }}
        />
      </div>
      {/* {activeMenu === 'dashboard' ? (
        <KTCard>
          <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Subbranch</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {totalDonationByMitraLembagaLoading ? <LoadingComponent /> : <></>}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Total Donasi</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {totalDonationByMitraLembaga?.data?.cities?.length === 0 ? (
                    <tr>
                      <td colSpan={10}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No Properties Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    totalDonationByMitraLembaga?.data?.cities?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a
                              href='#'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate('/apps/admin-subbranch-dashboard/dashboard', {
                                  state: {
                                    province_code: totalDonationByMitraLembaga?.data?.province?.acronym,
                                    city_code: item.acronym,
                                    community_code: totalDonationByMitraLembaga?.data?.community_code,
                                  },
                                })
                              }}
                            >
                              {item?.name?.toUpperCase()}
                            </a>
                          </td>
                          <td>{generateCurrencyFormat(item?.total_donation)}</td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </KTCard>
      ) : null} */}
      {activeMenu === 'donation' ? (
        <KTCard className='mt-10'>
          <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Donation</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Donation Management</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {donationLoading ? <LoadingComponent /> : <></>}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th>Donatur</th>
                    <th>Program</th>
                    <th>Amount</th>
                    <th>Kode Unik</th>
                    <th>Channel</th>
                    <th>Profiling</th>
                    <th>utm_campaign</th>
                    <th>Referal Code</th>
                    <th>Province</th>
                    <th>City</th>
                    <th>Transfer Date</th>
                    <th>CreatedAt</th>
                    <th>Status</th>
                    <th>E-Kwitansi</th>
                    <th>BSZ</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {donationItems.length === 0 ? (
                    <tr>
                      <td colSpan={6}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No Properties Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    donationItems?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {/* <span
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/crm/customer-management/customer/${item.customer_id}`)
                            }}
                          >
                            {item.customer?.values?.find(
                              (el) =>
                                el.name === 'nama-lengkap' ||
                                el.name === 'nama-perusahaan' ||
                                el.name === 'nama-komunitas'
                            )?.value ?? '-'}
                          </span> */}
                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                                {item.customer_name ?? '-'}
                              </a>
                              <span>{item.customer_email ?? '-'}</span>
                              <span>{item.customer_phone ?? '-'}</span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column'>
                              <span>{item.program?.title}</span>
                              <span className='badge badge-success'>
                                {item.program?.program_type}
                              </span>
                            </div>
                          </td>
                          <td>{generateCurrencyFormat(item.amount)}</td>
                          <td>{generateCurrencyFormat(item.unique_code)}</td>
                          <td>{item?.channel?.name}</td>
                          <td>{item?.customer_profiling ?? '-'}</td>
                          <td>{item?.utm_campaign}</td>
                          <td>
                            {item?.affiliate_code === '' ? item?.aff_code : item?.affiliate_code}
                          </td>
                          <td>{item?.province_name ?? '-'}</td>
                          <td>{item?.city_name ?? '-'}</td>
                          <td>{timeDateFormatter(Date.parse(item?.transfer_date?.toString()))}</td>
                          <td>{timeDateFormatter(Date.parse(item?.created_at?.toString()))}</td>
                          <td>{generateStatusBadge(item.status)}</td>
                          <td>
                            <span
                              className='badge badge-success'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                // setSelectedDonation(item);
                                navigate(
                                  `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                                )
                              }}
                            >
                              E-Kwitansi
                            </span>
                          </td>
                          <td>
                            <span
                              className='badge badge-success'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                // setSelectedDonation(item);
                                navigate(
                                  `/apps/donation/donation-management/bukti-setor-zakat?donation_id=${item.id}`
                                )
                              }}
                            >
                              Bukti Setor Zakat
                            </span>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div className='card-footer'>
              <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={'...'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageCount={donationModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={'pagination pagination-sm m-0 float-right'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </KTCard>
      ) : null}
    </>
  )
}

export default DashboardMitraLembagaScreen
