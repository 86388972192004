import {useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import FormsComponent from '../../../../forms/components/forms.component'
import {useCityStore} from '../../cities-management/states/city.state'
import {useDistrictStore} from '../../districts-management/states/district.state'
import {useProvinceStore} from '../../provinces-management/states/province.state'
import {useSubdistrictStore} from '../../subdistricts-management/states/subdistrict.state'
import {useAffiliatorCompanyFormStore} from '../states/affiliator-state'

const AffiliatorCompanyModalComponent = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          <div className='modal-content'>
            <UserCompanyModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <UserCompanyModalWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const UserCompanyModalHeader = () => {
  const {setField, formParam} = useAffiliatorCompanyFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const UserCompanyModalWrapper = () => {
  const {forms, setForms, setField, onSubmit, field, setFormSelectData} =
    useAffiliatorCompanyFormStore()
  const {getProvinces, provinceSelectData} = useProvinceStore()
  const {getCities, citySelectData} = useCityStore()
  const {getDistricts, districtSelectData} = useDistrictStore()
  const {getSubdistricts, subdistrictSelectData} = useSubdistrictStore()

  useEffect(() => {
    if (field !== null || field !== undefined) {
      getProvinces({page: 0, size: 100})
      // getCities({page: 0, size: 100, province_id: field?.province_id})
      // getDistricts({page: 0, size: 100, city_id: field?.city_id})
      // getSubdistricts({page: 0, size: 100, district_id: field?.district_id})
    } else {
      getProvinces({page: 0, size: 100})
      getCities({page: 0, size: 100})
      getDistricts({page: 0, size: 100})
      getSubdistricts({page: 0, size: 100})
    }
  }, [field])

  useEffect(() => {
    if (provinceSelectData?.length > 0) {
      setFormSelectData(7, provinceSelectData)
    }
  }, [provinceSelectData])

  useEffect(() => {
    if (citySelectData?.length > 0) {
      setFormSelectData(8, citySelectData)
    }
  }, [citySelectData])

  useEffect(() => {
    if (districtSelectData?.length > 0) {
      setFormSelectData(9, districtSelectData)
    }
  }, [districtSelectData])

  useEffect(() => {
    if (subdistrictSelectData?.length > 0) {
      setFormSelectData(10, subdistrictSelectData)
    }
  }, [subdistrictSelectData])
  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <FormsComponent
            forms={forms}
            setForms={setForms}
            uploadFileCb={(e) => console.log(e)}
            selectWithTextCb={(index, name, value) => {
              console.log(index, name, value)
              if (index === 7) {
                getCities({
                  page: 0,
                  size: 10,
                  province_id: value,
                })
              }
              if (index === 8) {
                getDistricts({
                  page: 0,
                  size: 10,
                  city_id: value,
                })
              }
              if (index === 9) {
                getSubdistricts({
                  page: 0,
                  size: 10,
                  district_id: value,
                })
              }
            }}
            selectWithTextInputChangeCb={(index, name, value) => {
              if (index === 10) {
                getProvinces({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 8) {
                getCities({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 9) {
                getDistricts({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 10) {
                getSubdistricts({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
            }}
          />
        </div>

        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => setField(undefined)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default AffiliatorCompanyModalComponent
