import {AxiosError} from 'axios'
import {ApiCrm} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {RoomKpiResultInterface, RoomResultInterface} from '../interfaces/room-result.interface'
import {RoomSingleResultInterface} from '../interfaces/room-single-result.interface'
import {TotalRoomInterface} from '../interfaces/total-rooms.interface'

export interface GetAllRoomParam {
  page?: number
  size?: number
  company_channel_property_id?: string
  status?: string
}

export const GetTotalRoom = async (): Promise<TotalRoomInterface> => {
  try {
    let response = await ApiCrm.get('/total-rooms')
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalRoomInterface = a.response?.data
    return response
  }
}

export const GetAdminHandleRoom = async (): Promise<RoomKpiResultInterface> => {
  try {
    let response = await ApiCrm.get('/rooms-kpi')
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: RoomKpiResultInterface = a.response?.data
    return response
  }
}

export const GetAllRoom = async (params: GetAllRoomParam): Promise<RoomResultInterface> => {
  try {
    let response = await ApiCrm.get('/rooms', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: RoomResultInterface = a.response?.data
    return response
  }
}

export const DeleteRoom = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete(`/rooms/${id}`)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const HandleRoom = async (id: string): Promise<RoomSingleResultInterface> => {
  try {
    let response = await ApiCrm.get(`/rooms_handle/${id}`)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: RoomSingleResultInterface = a.response?.data
    return response
  }
}

export const CloseRoom = async (id: string): Promise<RoomSingleResultInterface> => {
  try {
    let response = await ApiCrm.get(`/rooms_close/${id}`)
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: RoomSingleResultInterface = a.response?.data
    return response
  }
}
