import {create} from 'zustand'
import {ExchangeRateResult} from '../interfaces/exchange-rate-result.interface'
import {persist} from 'zustand/middleware'
import {GetExchangeRateIdr} from '../providers/exchange-rate.provider'

interface ExchangeRateState {
  exchangeRate: ExchangeRateResult
  getExchangeRate: () => Promise<void>
  selectedCurrency: string
  setSelectedCurrency: (currency: string) => void
}

export const useExchangeRateStore = create(
  persist<ExchangeRateState>(
    (set, get) => ({
      exchangeRate: undefined,
      selectedCurrency: localStorage.getItem('SELECTED_CURRENCY') ?? 'IDR',
      setSelectedCurrency: (currency: string) => {
        const rates = get().exchangeRate?.conversion_rates[currency]
        localStorage.setItem('EXCHANGE_RATE', rates)
        localStorage.setItem('SELECTED_CURRENCY', currency)
        console.log(rates, currency)
        set({selectedCurrency: currency})
      },
      getExchangeRate: async () => {
        const result = await GetExchangeRateIdr()
        set({exchangeRate: result})
      },
    }),
    {
      name: 'exchange_rate',
      getStorage: () => localStorage,
    }
  )
)
