import {AxiosError} from 'axios'
import {Api} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DistrictData, DistrictResultInterface} from '../interfaces/district-result.interface'
import {DistrictSingleResultInterface} from '../interfaces/district-single-result.interface'

export interface GetAllDistrictParam {
  page?: number
  size?: number
  city_id?: string
  name?: string
}

export const GetAllDistrict = async (
  params: GetAllDistrictParam
): Promise<DistrictResultInterface> => {
  try {
    let response = await Api.get('/districts', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DistrictResultInterface = a.response?.data
    return response
  }
}

export const ShowDistrict = async (id: string): Promise<DistrictSingleResultInterface> => {
  try {
    let response = await Api.get('/districts/' + id)
    return response.data as DistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDistrict = async (
  form: DistrictData
): Promise<DistrictSingleResultInterface> => {
  try {
    let response = await Api.post('/districts', form)
    return response.data as DistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDistrict = async (
  id: string,
  form: DistrictData
): Promise<DistrictSingleResultInterface> => {
  try {
    let response = await Api.put('/districts/' + id, form)
    return response.data as DistrictSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DistrictSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDistrict = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/districts/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
