import {create} from 'zustand'
import {ClientData, ClientResultInterface} from '../interfaces/clients-result.interface'
import {GetClientParam, GetAllClient} from '../providers/client.provider'

interface ClientState {
  clientLoading: boolean
  clientItems: ClientData[]
  clientModel?: ClientResultInterface
  getClient: (params: GetClientParam) => void
}

export const useClientStore = create<ClientState>((set, get) => ({
  clientLoading: false,
  clientItems: [],
  clientModel: undefined,
  getClient: async (params: GetClientParam) => {
    set({clientLoading: true, clientItems: []})
    const response = await GetAllClient(params)
    set({clientModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        set({clientItems: response?.data?.items})
      } else {
        set({clientItems: []})
      }
    } else {
      set({clientItems: []})
    }
    set({clientLoading: false})
  },
}))
