import {AxiosError} from 'axios'
import {ApiCrm, ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {GroupData, GroupResultInterface} from '../interfaces/group-management-result.interface'
import {GroupSingleResultInterface} from '../interfaces/group-management-single-result.interface'

export interface GetAllGroupParam {
  page?: number
  size?: number
  search?: string
}

export interface GetTotalDonationGroup {
  user_ids?: string[]
}

export const GetAllGroup = async (params: GetAllGroupParam): Promise<GroupResultInterface> => {
  try {
    let response = await ApiCrm.get('/work-group', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: GroupResultInterface = a.response?.data
    return response
  }
}

export const ShowGroup = async (id: string): Promise<GroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/work-group/' + id)
    return response.data as GroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GroupSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitGroup = async (form: GroupData): Promise<GroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/work-group', form)
    return response.data as GroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GroupSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateGroup = async (
  id: string,
  form: GroupData
): Promise<GroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/work-group/' + id, form)
    return response.data as GroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GroupSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteGroup = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/work-group/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const ShowMember = async (id: string): Promise<GroupSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/work-group-member/' + id)
    return response.data as GroupSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GroupSingleResultInterface = a.response?.data
    return response
  }
}

export const TotalDonationGroup = async (
  form: GetTotalDonationGroup
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.post('/total-donation-affiliator-group', form)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const TotalDonationGroupByTransfer = async (
  form: GetTotalDonationGroup
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.post('/total-donation-affiliator-group-by-transfer', form)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const TotalDonationGroupByTunai = async (
  form: GetTotalDonationGroup
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.post('/total-donation-affiliator-group-by-tunai', form)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const TotalDonationGroupV2 = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-group/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
