import {AxiosError} from 'axios'
import {ApiExchangeRate} from '../../../api/api'
import {ExchangeRateResult} from '../interfaces/exchange-rate-result.interface'

export const GetExchangeRateIdr = async (): Promise<ExchangeRateResult> => {
  try {
    let response = await ApiExchangeRate.get(
      `/v6/${process.env.REACT_APP_EXCHANGE_RATE_API_KEY}/latest/IDR`
    )
    return response.data as ExchangeRateResult
  } catch (e) {
    let a = e as AxiosError
    let response: ExchangeRateResult = a.response?.data
    return response
  }
}
