import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainTaqur = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/admin-taqur/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={'Dashboard'}
        hasBullet={true}
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Tabung Qurban'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin001.svg'
      >
        <SidebarMenuItem
          to='/apps/program/taqur/program-management/program'
          title='Program Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/taqur/properties-management/properties'
          title='Properties Managemant'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainTaqur
