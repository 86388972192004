import {AxiosError} from 'axios'
import {Api} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {ProvinceData, ProvinceResultInterface} from '../interfaces/province-result.interface'
import {ProvinceSingleResultInterface} from '../interfaces/province-single-result.interface'

export interface GetAllProvinceParam {
  page?: number
  size?: number
  name?: string
}

export const GetAllProvince = async (
  params: GetAllProvinceParam
): Promise<ProvinceResultInterface> => {
  try {
    let response = await Api.get('/provinces', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ProvinceResultInterface = a.response?.data
    return response
  }
}

export const ShowProvince = async (id: string): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await Api.get('/provinces/' + id)
    return response.data as ProvinceSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProvinceSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitProvince = async (
  form: ProvinceData
): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await Api.post('/provinces', form)
    return response.data as ProvinceSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProvinceSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateProvince = async (
  id: string,
  form: ProvinceData
): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await Api.put('/provinces/' + id, form)
    return response.data as ProvinceSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProvinceSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteProvince = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/provinces/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
