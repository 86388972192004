import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core'
import {KorcaListWrapper} from '../../../crm/korca-management/components/korca-list.component'
import DashboarDirectiondScreen from '../components/direction-dashboard.component'

const DirectionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Korca Management',
    path: '/apps/direction-screen/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DirectionScreen = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dashboard'
          element={
            <>
              <PageTitle breadcrumbs={DirectionBreadcrumbs}>Direction Dashboard</PageTitle>
              <DashboarDirectiondScreen />
              <KorcaListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/direction-screen/dashboard' />} />
    </Routes>
  )
}

export default DirectionScreen
