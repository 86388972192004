import {AxiosError} from 'axios'
import {SchoolData, SchoolResultInterface} from '../interfaces/school-result.interface'
import {SchoolSingleResultInterface} from '../interfaces/school-single-result.interface'
import {Api} from '../../../../../../api/api'
import {GeneralInterface} from '../../../../../../interfaces/general.interface'

export interface GetAllSchoolParam {
  page: number
  size: number
}

export const GetAllSchool = async (params: GetAllSchoolParam): Promise<SchoolResultInterface> => {
  try {
    let response = await Api.get('/school', {params: params})
    return response.data as SchoolResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SchoolResultInterface = a.response?.data
    return response
  }
}

export const ShowSchool = async (id: string): Promise<SchoolSingleResultInterface> => {
  try {
    let response = await Api.get('/school/' + id)
    return response.data as SchoolSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SchoolSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitSchool = async (form: SchoolData): Promise<SchoolSingleResultInterface> => {
  try {
    let response = await Api.post('/school', form)
    return response.data as SchoolSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SchoolSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateSchool = async (
  id: string,
  form: SchoolData
): Promise<SchoolSingleResultInterface> => {
  try {
    let response = await Api.put('/school' + id, form)
    return response.data as SchoolSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: SchoolSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteSchool = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/school/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
