import clsx from 'clsx'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {generateImageUrl} from '../../../helpers/generate-image-url'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {SelectInput} from '../../../../app/modules/forms/components/input.component'
import {useExchangeRateStore} from '../../../../app/modules/exchange/states/exchange.state'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const {setSelectedCurrency, selectedCurrency} = useExchangeRateStore()
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <SelectInput
          id={'currency'}
          title={''}
          name={'currency'}
          type={'select'}
          placeholder='Currency'
          selectData={[
            {label: 'IDR - Indonesia Rupiah', value: 'IDR'},
            {label: 'USD - US Dollar', value: 'USD'},
            {label: 'MYR - Malaysia Ringgit', value: 'MYR'},
            {label: 'TRY - Turkish Lira', value: 'TRY'},
            {label: 'EUR - Euro', value: 'EUR'},
            {label: 'GBP - Poundsterling', value: 'GBP'},
            {label: 'AUD - Australian Dollar', value: 'AUD'},
            {label: 'CNY - Chinese Yuan', value: 'CNY'},
            {label: 'INR - Indian Rupee', value: 'INR'},
            {label: 'THB - Thai Baht', value: 'THB'},
            {label: 'JPY - Japan Yen', value: 'JPY'},
          ]}
          value={selectedCurrency}
          withMarginBottom={false}
          onChangeSelect={(e) => {
            if (e.target.value?.length > 0) {
              setSelectedCurrency(e.target?.value)
            } else {
              setSelectedCurrency('IDR')
            }
          }}
        />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {currentUser?.data?.avatar === '' ? (
            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
          ) : (
            <img src={generateImageUrl(currentUser?.data?.avatar)} alt='' />
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
