import {create} from 'zustand'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {GetTotalRelawanKorca, ShowKorca, UpdateKorca} from '../providers/korca-management.provider'
import {toast} from 'react-toastify'
import {generateValueLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {GroupWorks, KorcaSingleResultInterface} from '../interfaces/korca-single-result.interface'
import {KorcaData, GroupWork} from '../interfaces/korca-result.interface'

interface KorcaDetailState {
  korcaDetailLoading: boolean
  korcaDetailModel?: KorcaSingleResultInterface
  getKorcaDetail: (id: string) => void
  reset: () => void
}

export const useKorcaDetailStore = create<KorcaDetailState>((set, get) => ({
  korcaDetailLoading: false,
  korcaDetailModel: undefined,
  getKorcaDetail: async (id: string) => {
    set({korcaDetailLoading: true})
    const response = await ShowKorca(id)
    if (response.status) {
      set({korcaDetailModel: response})
    } else {
      set({korcaDetailModel: undefined})
    }
    set({korcaDetailLoading: false})
  },
  reset: () => {
    set({
      korcaDetailLoading: false,
      korcaDetailModel: undefined,
    })
  },
}))

interface GroupWorkFormState {
  field?: KorcaData
  setField?: (item: KorcaData) => void
  korcaDetailModel?: KorcaSingleResultInterface
  korcaDetailLoading: boolean
  korcaDetailForms: FormInput[]
  getKorcaDetail?: (id: string) => void
  setKorcaDetailForms?: (index: number, value: any) => void

  groupWorkForms?: FormInput[]
  groupWorkField?: GroupWork
  setGroupWorkField?: (item: GroupWork) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  generateGroupWorkForms?: (formParam: FormProps, index: number) => void
  groupWork?: GroupWorks[]
  groupWorkOri?: GroupWork[]
  setGroupWorkForms?: (index: number, value: any) => void
  onSubmitGroupWorkForms?: () => void
  groupWorkFormProps?: FormProps
  onDeleteGroupWork?: (index: number) => void

  onSubmitGroupWork?: () => void

  korcaTotalRelawan: number
  korcaTotalRelawanLoading: boolean
  getKorcaTotalRelawan: (id: string) => void
}

export const useGroupWorkFormStore = create<GroupWorkFormState>((set, get) => ({
  korcaDetailModel: undefined,
  korcaDetailLoading: false,
  korcaDetailForms: [],

  korcaTotalRelawanLoading: false,
  korcaTotalRelawan: 0,
  getKorcaTotalRelawan: async (id: string) => {
    set({korcaTotalRelawanLoading: true, korcaTotalRelawan: 0})
    const response = await GetTotalRelawanKorca(id)
    if (response.data) {
      if (response.data) {
        set({
          korcaTotalRelawan: response?.data,
        })
      } else {
        set({
          korcaTotalRelawan: 0,
        })
      }
    }
    set({korcaTotalRelawanLoading: false})
  },

  groupWorkOri: [],
  field: undefined,
  reset: () => {
    set({groupWork: [], field: undefined, korcaDetailModel: undefined})
  },
  setField: (item: KorcaData) => {
    set({field: item})
  },
  getKorcaDetail: async (id: string) => {
    set({korcaDetailLoading: true, groupWork: [], groupWorkOri: [], korcaDetailModel: undefined})
    const response = await ShowKorca(id)
    set({korcaDetailModel: response})
    if (response.data) {
      if (response.data) {
        set({
          groupWork: response?.data?.group_works,
          groupWorkOri: response?.data?.group_work,
          field: response.data,
        })
      } else {
        set({
          groupWork: [],
          groupWorkOri: [],
          field: undefined,
        })
      }
    }
    set({korcaDetailLoading: false})
  },
  generateKorcaDetailForms: (formParam: FormProps) => {
    let forms: FormInput[] = []

    forms = [
      {
        id: 'district_lead',
        title: 'Nama Korca',
        placeholder: 'Nama Korca...',
        type: 'select-with-text',
        name: 'district_lead',
        value: {
          label: get().field?.district_lead,
          value: get().field?.district_lead,
        },
        selectData: [],

        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'province_id',
        title: 'Provinsi',
        placeholder: 'Provinsi...',
        type: 'select-with-text',
        name: 'province_id',
        value: {
          label: get().field?.province_name,
          value: get().field?.province_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'city_id',
        title: 'Kota/Kabupaten',
        placeholder: 'Kota/Kabupaten...',
        type: 'select-with-text',
        name: 'city_id',
        value: {
          label: get()?.field?.city_name,
          value: get()?.field?.city_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'district_id',
        title: 'Kecamatan',
        placeholder: 'Kecamatan...',
        type: 'select-with-text',
        name: 'district_id',
        value: {
          label: get().field?.district_name,
          value: get().field?.district_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'subdistrict_id',
        title: 'Kelurahan',
        placeholder: 'Kelurahan...',
        type: 'select-with-text',
        name: 'subdistrict_id',
        value: {
          label: get().field?.subdistrict_name,
          value: get().field?.subdistrict_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({korcaDetailForms: forms})
  },
  setKorcaDetailForms: (index: number, value: any) => {
    const forms = [...get().korcaDetailForms]
    if (forms[index].type === 'multiple-text') {
      forms[index] = {
        ...forms[index],
        values: value,
      }
    } else {
      forms[index] = {
        ...forms[index],
        value: value,
      }
    }
    set({korcaDetailForms: forms})
  },

  groupWork: [],
  generateGroupWorkForms: (formParam: FormProps, index: number) => {
    set({groupWorkFormProps: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'group_id',
        title: 'Group',
        type: 'select-with-text',
        name: 'group_id',
        value: {
          label: get()?.groupWork[index]?.group_name,
          value: get()?.groupWork[index]?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({groupWorkForms: forms})
  },
  groupWorkForms: [],
  setGroupWorkField: (item: GroupWork) => {
    set({groupWorkField: item})
  },
  groupWorkField: undefined,
  setGroupWorkForms: (index: number, value: any) => {
    const forms = [...get().groupWorkForms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({groupWorkForms: forms})
  },
  onSubmitGroupWorkForms: () => {
    let form = {}
    for (const item of get().groupWorkForms) {
      form = {
        ...form,
        [item.name]: generateValueLogic(item.type, item.value),
      }
    }
    const groupWorks = [...get()?.groupWork]

    if (get().groupWorkFormProps?.action === FormAction.CREATE) {
      groupWorks.push(form)
      set({groupWork: groupWorks})
    } else {
      groupWorks[get().groupWorkFormProps?.index] = form
      set({groupWork: groupWorks})
    }
  },
  onDeleteGroupWork: (index: number) => {
    const groupWorks = [...get().groupWork]
    groupWorks.splice(index, 1)
    set({groupWork: groupWorks})
  },

  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const groupWorkForms = [...get().groupWorkForms]
    groupWorkForms[index] = {
      ...groupWorkForms[index],
      selectData: selectData,
    }
    set({groupWorkForms: groupWorkForms})
  },
  onSubmitGroupWork: async () => {
    let forms = {}

    forms = {
      ...forms,
      district_lead: get().korcaDetailModel?.data?.district_lead,
      province_id: get().korcaDetailModel?.data?.province_id,
      city_id: get().korcaDetailModel?.data?.city_id,
      district_id: get().korcaDetailModel?.data?.district_id,
      subdistrict_id: get().korcaDetailModel?.data?.subdistrict_id,
      group_work: get().groupWork,
    }
    const response = await UpdateKorca(get().korcaDetailModel?.data?.id, forms)
    if (response.status) {
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
  },
}))
