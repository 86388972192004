import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../auth'
import {useEffect, useState} from 'react'
import DashboardCardAffiliatorComponent from '../../../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import {generateCurrencyFormat} from '../../../../../../helpers/generate-currency-format'
import SchoolListWrapper from '../../school-management/components/school-list.component'
import TransactionListWrapper from '../../transaction-management/components/transaction-management-list.component'
import {useSchoolState} from '../../school-management/states/school.state'
import {useTaqurTransactionStore} from '../../transaction-management/states/transaction-management.state'
import {useCustomerStore} from '../../../../crm/customer-management/states/customer.state'

const dashboardTaqurBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard Tabungan Qurban',
    path: '/apps/admin-taqur/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardTaqurScreen = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dashboard'
          element={
            <>
              <DashboardTaqurComponent />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/admin-taqur/dashboard' />} />
    </Routes>
  )
}

const DashboardTaqurComponent = () => {
  const {currentUser} = useAuth()
  const {totalTransactionTaqur, totalTransactionTaqurItem, totalTransactionTaqurLoading} =
    useTaqurTransactionStore()

  const {schoolModel} = useSchoolState()
  const [activeMenu, setActiveMenu] = useState<string>('dashboard')
  const {getCustomers, customerModel, customerLoading} = useCustomerStore()
  useEffect(() => {
    getCustomers({
      page: 0,
      size: 10,
      reference: 'taqur_yakesma',
    })
    totalTransactionTaqur()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={dashboardTaqurBreadcrumbs}>
        {`DASHBOARD - ${currentUser?.data?.full_name}`}
      </PageTitle>
      <div className='row gy-5 g-xl-8 mb-5'>
        <DashboardCardAffiliatorComponent
          title='Jumlah Sekolah'
          color='success'
          value={schoolModel?.data?.total}
          subtitle='Jumlah Sekolah'
          loading={false}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('dashboard')
          }}
        />
        <DashboardCardAffiliatorComponent
          title='Tabungan Terkumpul'
          color='success'
          value={generateCurrencyFormat(totalTransactionTaqurItem)}
          subtitle='Tabungan Terkumpul'
          loading={totalTransactionTaqurLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('transaction')
          }}
        />
        <DashboardCardAffiliatorComponent
          title='Jumlah Siswa'
          color='success'
          value={customerModel?.data?.total}
          subtitle='Jumlah Donatur'
          loading={customerLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('dashboard')
          }}
        />
      </div>
      {activeMenu === 'dashboard' ? <SchoolListWrapper /> : null}
      {activeMenu === 'transaction' ? <TransactionListWrapper /> : null}
    </>
  )
}

export default DashboardTaqurScreen
