import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMainAdminSubBranch = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/admin-subbranch-dashboard/dashboard'
        title='Dashboard'
        hasBullet={true}
      />
      {/* <SidebarMenuItem
        to='/apps/direction-screen/dashboard'
        title='Direksi Dashboard'
        hasBullet={true}
      />
      <SidebarMenuItem
        to='/apps/korca-member-management/korca-member'
        title='Korca Member'
        hasBullet={true}
      /> */}
    </>
  )
}

export default SidebarMenuMainAdminSubBranch
