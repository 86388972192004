import {AxiosError} from 'axios'
import {ApiCrm, ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {KorcaData, KorcaResultInterface} from '../interfaces/korca-result.interface'
import {
  KorcaSingleResultInterface,
  KorcaTotalRelawanResultInterface,
} from '../interfaces/korca-single-result.interface'

export interface GetAllKorcaParam {
  page?: number
  size?: number
  search?: string
  filters?: string
}

export const GetAllKorca = async (params: GetAllKorcaParam): Promise<KorcaResultInterface> => {
  try {
    let response = await ApiCrm.get('/district-group', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaResultInterface = a.response?.data
    return response
  }
}

export const ShowKorca = async (id: string): Promise<KorcaSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/district-group/' + id)
    return response.data as KorcaSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaSingleResultInterface = a.response?.data
    return response
  }
}

export const GetTotalRelawanKorca = async (
  id: string
): Promise<KorcaTotalRelawanResultInterface> => {
  try {
    let response = await ApiProgram.get('/get-affiliators-total/' + id)
    return response.data as KorcaTotalRelawanResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaTotalRelawanResultInterface = a.response?.data
    return response
  }
}

export const UpdateKorca = async (
  id: string,
  form: KorcaData
): Promise<KorcaSingleResultInterface> => {
  try {
    let response = await ApiCrm.put('/district-group/' + id, form)
    return response.data as KorcaSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitKorca = async (form: KorcaData): Promise<KorcaSingleResultInterface> => {
  try {
    let response = await ApiCrm.post('/district-group', form)
    return response.data as KorcaSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteKorca = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete('/district-group/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const ShowKorcaDetail = async (id: string): Promise<KorcaSingleResultInterface> => {
  try {
    let response = await ApiCrm.get('/district-group-korca/' + id)
    return response.data as KorcaSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: KorcaSingleResultInterface = a.response?.data
    return response
  }
}
