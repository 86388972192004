import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMainRelawan = () => {
  return (
    <>
      <SidebarMenuItem to='/apps/program-affiliate/dashboard' title='Relawan' hasBullet={true} />
    </>
  )
}

export default SidebarMenuMainRelawan
