import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core'
import CustomerByAffiliatorListWrapper from '../../../auth/affiliator-management/components/customer-affiliator-list.component'
import AffiliatorDetailScreen from '../../../auth/affiliator-management/components/detail-affiliator.componnet'
import {GroupMemberListWrapper} from '../components/group-detail-list.component'

const GroupMemberBreadcrumbs: Array<PageLink> = [
  {
    title: 'Group Member',
    path: '/apps/member-management/group-member',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MemberDetailBreadcrumbs: Array<PageLink> = [
  {
    title: 'Group Member',
    path: '/apps/member-management/group-member',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Member Detail',
    path: '/apps/member-management/member-detail',
    isSeparator: false,
    isActive: false,
  },
]
const MemberScreen = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='group-member'
          element={
            <>
              <PageTitle breadcrumbs={GroupMemberBreadcrumbs}>Group Dashboard</PageTitle>
              <GroupMemberListWrapper />
            </>
          }
        />
        <Route
          path='member-detail/:userId'
          element={
            <>
              <PageTitle breadcrumbs={MemberDetailBreadcrumbs}>Group Member Detail</PageTitle>
              <AffiliatorDetailScreen />
              <CustomerByAffiliatorListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/member-management/group-member' />} />
    </Routes>
  )
}

export default MemberScreen
