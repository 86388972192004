import {useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import FormsComponent from '../../../../forms/components/forms.component'
import {useCustomerStore} from '../../../crm/customer-management/states/customer.state'
import {useProspectChannelStore} from '../../../crm/prospect-channel-management/states/prospect-channel.state'
import {usePaymentStore} from '../../payment/states/payment.state'
import {useProgramStore} from '../../program/states/program.state'
import {useDonationAffiliateFormStore} from '../states/program-affiliate.state'

const DonationAffiliateModalComponent = () => {
  const {setFormSelectData} = useDonationAffiliateFormStore()
  const {getPrograms, programSelectDatas} = useProgramStore()
  const {getCustomersAffiliate, customerSelectDatas} = useCustomerStore()
  const {getPayments, paymentSelectDatas} = usePaymentStore()
  const {getProspectChannels, prospectChannelSelectDatas} = useProspectChannelStore()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getPrograms({
      page: 0,
      size: 100000,
    })
    getCustomersAffiliate({
      page: 0,
      size: 100000,
    })
    getPayments({
      page: 0,
      size: 100000,
    })
    getProspectChannels({
      page: 0,
      size: 1000,
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (programSelectDatas?.length > 0) {
      setFormSelectData(2, programSelectDatas)
    }
  }, [programSelectDatas])

  useEffect(() => {
    if (customerSelectDatas?.length > 0) {
      setFormSelectData(0, customerSelectDatas)
    }
  }, [customerSelectDatas])

  useEffect(() => {
    if (paymentSelectDatas?.length > 0) {
      setFormSelectData(3, paymentSelectDatas)
    }
  }, [paymentSelectDatas])

  useEffect(() => {
    if (prospectChannelSelectDatas?.length > 0) {
      setFormSelectData(5, prospectChannelSelectDatas)
    }
  }, [prospectChannelSelectDatas])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <DonationModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <DonationModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const DonationModalHeader = () => {
  const {setField, formParam} = useDonationAffiliateFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const DonationModalFormWrapper = () => {
  const {forms, setForms, setField} = useDonationAffiliateFormStore()

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <FormsComponent forms={forms} setForms={setForms} uploadFileCb={(e) => console.log(e)} />
        </div>

        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => setField(undefined)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            // onClick={(e) => {
            //   e.preventDefault()
            //   onSubmit(navList[activeNavIndex].reference)
            // }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default DonationAffiliateModalComponent
