import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {DistrictData, DistrictResultInterface} from '../interfaces/district-result.interface'
import {GetAllDistrict, GetAllDistrictParam} from '../providers/district.provider'
import {create} from 'zustand'

interface DistrictState {
  districtLoading: boolean
  districtItems: DistrictData[]
  districtSelectData: SelectData[]
  districtModel?: DistrictResultInterface
  getDistricts: (params: GetAllDistrictParam) => void
  onDelete?: (id: string, params: GetAllDistrictParam) => void
  reset?: () => void
}

export const useDistrictStore = create<DistrictState>((set, get) => ({
  reset: () => {
    set({
      districtSelectData: [],
      districtModel: undefined,
      districtItems: [],
    })
  },
  districtLoading: false,
  districtItems: [],
  districtSelectData: [],
  districtModel: undefined,
  getDistricts: async (params: GetAllDistrictParam) => {
    set({districtLoading: true})
    const response = await GetAllDistrict(params)
    if (response.status) {
      if (response.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          districtSelectData: transformDatas,
          districtItems: response.data.items,
        })
      } else {
        set({
          districtSelectData: [],
          districtItems: [],
        })
      }
    } else {
      set({
        districtSelectData: [],
        districtItems: [],
      })
    }
    set({districtLoading: false})
  },
  onDelete: (id: string, params: GetAllDistrictParam) => {},
}))
