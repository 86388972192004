import {InputDatePicker} from '../../../../forms/components/input.component'
import TopProgramCardComponent from '../../../dashboard/components/top-program-card.component'
import TotalActiveProgramCardComponent from '../../../dashboard/components/total-active-program-card.component'
import TotalCustomerCardComponent from '../../../dashboard/components/total-customer-card.component'
import TotalDonationCardComponent from '../../../dashboard/components/total-donation.card.component'
import {useDashboardStore} from '../../../dashboard/states/dashboard.state'

const DashboarDirectiondScreen = () => {
  const {startDate, endDate, setStartDate, setEndDate} = useDashboardStore()

  return (
    <>
      <div className='row gy-5 g-xl-10'>
        <div className='col-12' style={{marginBottom: -20}}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>
        <TotalDonationCardComponent />
        <TotalCustomerCardComponent />
        <TotalActiveProgramCardComponent />
        <TopProgramCardComponent />
      </div>
    </>
  )
}

export default DashboarDirectiondScreen
