import moment from 'moment'
import {useEffect} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {generateCurrencyFormat} from '../../../../helpers/generate-currency-format'
import {LoadingComponent} from '../../crm/properties-management/components/loading.component'
import {useDashboardStore} from '../states/dashboard.state'

const TopProgramCardComponent = () => {
  const {getTopPrograms, topPrograms, topProgramLoading, startDate, endDate} = useDashboardStore()

  useEffect(() => {
    console.log('zxczxc', startDate, endDate)
    getTopPrograms({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    })
  }, [startDate, endDate])
  useEffect(() => {
    console.log('zxczxc', topProgramLoading)
  }, [topProgramLoading])

  return (
    <>
      <div className='col-sm-12 col-xl-12 mb-xl-12'>
        <KTCard>
          <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Top 10 Programs</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {topProgramLoading ? <LoadingComponent /> : <></>}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th>#</th>
                    <th>Title</th>
                    <th>Total Donations</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {topPrograms?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{generateCurrencyFormat(item.total_donations)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </KTCard>
      </div>
    </>
  )
}

export default TopProgramCardComponent
