import {useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {FormAction, FormProps} from '../../../../../interfaces/form-action-interfaces'
import {useAuth} from '../../../../auth'
import {LoadingComponent} from '../../properties-management/components/loading.component'
import {useFlowBuilderFormState, useFlowBuilderStore} from '../states/flow-builder.state'

const FlowBuilderListComponent: React.FC<FlowBuilderListWrapperProps> = ({flowType}) => {
  const navigate = useNavigate()
  const {flowLoading, flowItems, getFlows, getTransactionFlows, onDelete, flowModel} =
    useFlowBuilderStore()
  const {currentUser} = useAuth()

  const {setField} = useFlowBuilderFormState()

  useEffect(() => {
    if (currentUser !== undefined) {
      if (flowType === 'marketing') {
        getFlows({
          page: 0,
          size: 10,
          company_id: currentUser?.data?.company?.id,
        })
      } else {
        getTransactionFlows({
          page: 0,
          size: 10,
          company_id: currentUser?.data?.company?.id,
        })
      }
    }
  }, [currentUser, flowType])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getFlows({page: selectedPage, size: 10, company_id: currentUser?.data?.company?.id})
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flow Builder</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Flow Builder Management</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(undefined)
                navigate(`/apps/chat/flow-builder-management/flow-builder-creator`, {
                  state: {
                    action: FormAction.CREATE,
                    title: 'Flow Builder',
                    subtitle: 'Form Flow Builder',
                  } as FormProps,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New Flow Builder
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {flowLoading === true ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Flow Name</th>
                  <th>Trigger</th>
                  <th>Flow Type</th>
                  <th>Number</th>
                  <th className='text-end'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {flowItems.length === 0 ? (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Flow Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  flowItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.flow_name}</td>
                        <td>{item.trigger}</td>
                        <td>{item.flow_type}</td>
                        <td>{item.company_channel_property?.account_id}</td>
                        <td className='text-end'>
                          <a
                            href={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setField(item)
                              navigate(`/apps/chat/flow-builder-management/flow-builder-creator`, {
                                state: {
                                  action: FormAction.UPDATE,
                                  title: 'Flow Builder',
                                  subtitle: 'Form Flow Builder',
                                  id: item.id,
                                  data: item,
                                } as FormProps,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, {
                                page: 0,
                                size: 10,
                                company_id: currentUser?.data?.company?.id,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className='card-footer'>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageCount={flowModel?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination pagination-sm m-0 float-right'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </KTCard>
    </>
  )
}

interface FlowBuilderListWrapperProps {
  flowType: string
}

const FlowBuilderListWrapper: React.FC<FlowBuilderListWrapperProps> = ({flowType}) => {
  return (
    <>
      <FlowBuilderListComponent flowType={flowType} />
    </>
  )
}

export default FlowBuilderListWrapper
