import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../../api/api'
import {ToatalTabunganInterface} from '../interfaces/transaction-management-result.interface'
import {DonationSingleResultInterface} from '../../../donation/interfaces/donation-single-result.interface'

export interface GetAllTransactionParam {
  page?: number
  size?: number
}

export const SubmitTransactionTaqurExcel = async (
  form: FormData
): Promise<DonationSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/taqur', form)
    return response.data as DonationSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationSingleResultInterface = a.response?.data
    return response
  }
}

export const TotalTabungan = async (): Promise<ToatalTabunganInterface> => {
  try {
    let response = await ApiProgram.get('/total-tabungan-qurban')
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ToatalTabunganInterface = a.response?.data
    return response
  }
}
