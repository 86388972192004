import {useIntl} from 'react-intl'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainLeadBranch = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem to='/dashboard' title='Dashboard' hasBullet={true} />
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItemWithSub
          to='/apps/chat/omni-management/omni'
          title='OMNI Channel'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/omni-management/new?status=new'}
            title={'New Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/open?status=open'}
            title={'Open Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/close?status=close'}
            title={'Close Case'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to='/apps/chat/flow-builder-management/'
          title='Flow Builder'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/marketing'}
            title={'Marketing'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/transactional'}
            title={'Transactional'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/apps/chat/broadcast-management/broadcasts'
          title='Broadcast'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/chat/contact-group-management/contact-groups'
          title='Contact Group'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='CRM'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/channel-management/channel'
          title='Channel Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/customer-management/customer'
          title={`${intl.formatMessage({id: 'APPS.CUSTOMER'})} Management`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/prospect-management/prospect'
          title='Prospect Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/task-management/tasks'
          title='Task Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/report-management/report'
          title='E-Report Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/donation'
        title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})}`}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com017.svg'
      >
        <SidebarMenuItem
          to='/apps/donation/donation-management/donation'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})}`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Campaign Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/program/news-management/news'
          title='News Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/banner-management/banner'
          title='Banner Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/program/program-event-banner-management/program-event-banner'
          title='Program Event Banner Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section  text-white text-uppercase fs-8 ls-1'>Master Data</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/master/crm'
        title='CRM'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/master/crm/properties-management/properties'
          title='Properties Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/currency-management/currencies'
          title='Currency'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/prospect-channel-management/prospect-channels'
          title={`Channel ${intl.formatMessage({id: 'APPS.TRANSACTION'})}`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/report-category-management/report-category'
          title='E-Report Category'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/message-template-management/message-template'
          title='Message Template'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/email-template-management/email-template'
          title='Email Template'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/master/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/master/program/donation-target-management/donation-target'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})} Target`}
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to='/apps/program/channel-management/channel'
          title='Channel'
          hasBullet={true}
        /> */}
        <SidebarMenuItem
          to='/master/program/payment-management/payment'
          title='Payment'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/program-category-management/program-category'
          title='Program Category'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/donation-type-management/donation-type'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})} Type`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainLeadBranch
