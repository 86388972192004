import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMainDireksi = () => {
  return (
    <>
      <SidebarMenuItem to='/apps/direction-screen/dashboard' title='Direksi' hasBullet={true} />
    </>
  )
}

export default SidebarMenuMainDireksi
