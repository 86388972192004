import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'

import {
  GroupResultInterface,
  GroupData,
  GroupUser,
} from '../interfaces/group-management-result.interface'
import {
  GetAllGroupParam,
  GetAllGroup,
  DeleteGroup,
  SubmitGroup,
  UpdateGroup,
  ShowMember,
  TotalDonationGroup,
  GetTotalDonationGroup,
  TotalDonationGroupByTransfer,
  TotalDonationGroupByTunai,
} from '../providers/group-management.provider'
import {GroupSingleResultInterface} from '../interfaces/group-management-single-result.interface'

interface GroupState {
  groupLoading: boolean
  groupItems: GroupData[]
  groupModel?: GroupResultInterface
  getGroup: (param: GetAllGroupParam) => void
  groupSelectDatas: SelectData[]
  onDelete: (id: string, params: GetAllGroupParam) => void

  getMember: (id: string) => void
  memberItems?: GroupData

  // Total donation group by affiliate
  getTotalDonationGroupByAffiliate: (params: GetTotalDonationGroup) => void
  totalDonationGroupByAffiliateItems?: number
  totalDonationGroupByAffiliateLoading: boolean

  // total donation group by transfer
  getTotalDonationGroupByTransfer: (params: GetTotalDonationGroup) => void
  totalDonationGroupByTransferItems?: number
  totalDonationGroupByTransferLoading: boolean

  // total donation group by tunai
  getTotalDonationGroupByTunai: (params: GetTotalDonationGroup) => void
  totalDonationGroupByTunaiItems?: number
  totalDonationGroupByTunaiLoading: boolean
}

export const useGroupStore = create<GroupState>((set, get) => ({
  groupLoading: false,
  groupItems: [],
  groupSelectDatas: [],
  memberItems: undefined,
  groupModel: undefined,
  getGroup: async (params: GetAllGroupParam) => {
    set({groupLoading: true})
    const response = await GetAllGroup(params)
    set({groupModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.group_name,
            value: item.id,
          })
        }
        set({
          groupSelectDatas: transformDatas,
          groupItems: response.data.items,
        })
      } else {
        set({
          groupSelectDatas: [],
          groupItems: [],
        })
      }
    } else {
      set({
        groupSelectDatas: [],
        groupItems: [],
      })
    }
    set({groupLoading: false})
  },
  onDelete: async (id: string, params: GetAllGroupParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({groupLoading: true})
      const response = await DeleteGroup(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getGroup(params)
      set({groupLoading: false})
    }
  },

  getMember: async (id: string) => {
    const response = await ShowMember(id)
    if (response.status) {
      set({memberItems: response.data})
    }
  },
  // total donation group by affiliate
  totalDonationGroupByAffiliateLoading: false,
  totalDonationGroupByAffiliateItems: 0,
  getTotalDonationGroupByAffiliate: async (params: GetTotalDonationGroup) => {
    set({totalDonationGroupByAffiliateLoading: true})
    let response = await TotalDonationGroup(params)
    if (response.status) {
      if (response.data) {
        set({totalDonationGroupByAffiliateItems: response.data})
      } else {
        set({totalDonationGroupByAffiliateItems: 0})
      }
    } else {
      set({totalDonationGroupByAffiliateItems: 0})
    }
    set({totalDonationGroupByAffiliateLoading: false})
  },
  // total donation group by transfer
  totalDonationGroupByTransferLoading: false,
  totalDonationGroupByTransferItems: 0,
  getTotalDonationGroupByTransfer: async (params: GetTotalDonationGroup) => {
    set({totalDonationGroupByTransferLoading: true})
    let response = await TotalDonationGroupByTransfer(params)
    if (response.status) {
      if (response.data) {
        set({totalDonationGroupByTransferItems: response.data})
      } else {
        set({totalDonationGroupByTransferItems: 0})
      }
    } else {
      set({totalDonationGroupByTransferItems: 0})
    }
    set({totalDonationGroupByTransferLoading: false})
  },
  // total donation group by tunai
  totalDonationGroupByTunaiLoading: false,
  totalDonationGroupByTunaiItems: 0,
  getTotalDonationGroupByTunai: async (params: GetTotalDonationGroup) => {
    set({totalDonationGroupByTunaiLoading: true})
    let response = await TotalDonationGroupByTunai(params)
    if (response.status) {
      if (response.data) {
        set({totalDonationGroupByTunaiItems: response.data})
      } else {
        set({totalDonationGroupByTunaiItems: 0})
      }
    } else {
      set({totalDonationGroupByTunaiItems: 0})
    }
    set({totalDonationGroupByTunaiLoading: false})
  },
}))

interface GroupFormState {
  field?: GroupData
  setField?: (item: GroupData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  onSubmit?: () => void
  formLoading?: boolean
  submitDone?: boolean
  groupData?: GroupData
  fieldItems: GroupUser[]
}

const formInitialState = {
  loading: false,
  form: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
  groupData: undefined,
}

export const useGroupFormStore = create<GroupFormState>((set, get) => ({
  field: undefined,
  fieldItems: [],
  serviceData: undefined,
  setField: (item: GroupData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []

    forms = [
      {
        id: 'group_name',
        title: 'Nama Group',
        placeholder: 'Group Name...',
        type: 'text',
        name: 'group_name',
        value: get().field?.group_name,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'group_code',
        title: 'Kode Group',
        placeholder: 'Group Code...',
        type: 'text',
        name: 'group_code',
        value: get().field?.group_code,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'ward_code',
        title: 'Kode Kecamatan',
        type: 'select-with-text',
        name: 'ward_code',
        value: {
          label: get().field?.ward_code,
          value: get().field?.ward_code,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }
    form = {...form, group_users: get()?.field?.group_users ?? []}
    let res: GroupSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitGroup(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateGroup(get().formParam?.id, form)

    if (res.status) {
      toast.success(res.message)
      set({groupData: res.data, submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({groupData: res.data, submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
